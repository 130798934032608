import { Component } from 'react';

import AuthInstance from '../../utils/Auth';
import UserContext from '../contexts/UserContext';

export const authStatuses = {
	LOADING: 0,
	LOGGED_OUT: 1,
	LOGGED_IN: 2,
	GETTING_USER_DATA: 3,
	UNAUTHORIZED: 4,
}

const VmsRealtimeService = window.CorusJwplayer.VmsRealtimeService;
const config = JSON.parse(localStorage.getItem('config'))
const watchHistoryDurationInDays = config?.configuration?.entries?.application?.watchHistoryDurationInDays || 0;  // set default value to '0' to so no watch history should be remembered

export default class VmsAuth extends Component {
	static contextType = UserContext;

	authStatus = authStatuses.LOADING;

	componentDidMount() {
		VmsRealtimeService.setup({
			environmentUrl: process.env.REACT_APP_VMS_RTS_DOMAIN,
		});

		AuthInstance.getConfiguration();

		if (VmsRealtimeService.suid) {
			this.authStatus = authStatuses.LOGGED_IN;
			this.context.updateIfChanged('isVmsAuthInProgress', false);
		}
	}

	componentDidUpdate() {
		if (this.context.signedIn && this.authStatus !== authStatuses.LOGGED_IN && this.authStatus !== authStatuses.UNAUTHORIZED) {
			// User is signed in in Adobe, but not yet here - need to take some action.
			if (!this.context.authZToken && this.authStatus !== authStatuses.GETTING_USER_DATA) {
				// Need to create an auth token and get user ID before creating a VMS account.
				this.getUserData();
			} else if (
				this.context.authZToken &&
				this.context.adobeUid &&
				this.context.authorizedResourcesChecked
			) {
				if (this.context.authorizedResources.length > 0) {
					// AuthZ token gotten, authorized resources checked. Good to go.
					this.createAuthenticatedUser();
				} else {
					this.authStatus = authStatuses.UNAUTHORIZED;
					this.context.updateIfChanged('isVmsAuthInProgress', false);
				}
			}
		} else if (
			this.authStatus !== authStatuses.LOGGED_OUT &&
			!this.context.signedIn &&
			!this.context.isAuthInProgress
		) {
			// User is not signed in. Log them out (if applicable); create an anonymous user (if not created).
			if (this.authStatus === authStatuses.LOGGED_IN) {
				this.logoutUser();
			}
			if (!VmsRealtimeService.duid || !localStorage.getItem('puid')) {
				this.createAnonymousUser();
			} else {
				this.context.updateIfChanged('isVmsAuthInProgress', false);
			}
		}
	}

	async getUserData() {
		this.authStatus = authStatuses.GETTING_USER_DATA;

		// Try to get authorization for the appropriate user's resource.
		if (this.context.authorizedResourcesChecked) {
			if (this.context.authorizedResources.includes('globaltv')) {
				// Use GlobalTV as a priority.
				AuthInstance.getAuthorization('globaltv');
			} else if (this.context.authorizedResources.length === 0) {
				// User has access to no resources.
				this.context.updateIfChanged('authZToken', '-1');
			} else {
				// Use the first authorized resource.
				AuthInstance.getAuthorization(this.context.authorizedResources[0]);
			}
		} else {
			// Try GlobalTV as a fallback. Most users should have authorization to this...
			// TODO: do we want to delay the authorization further to ensure that we have resources checked?
			AuthInstance.getAuthorization('globaltv');
		}
	}

	//// Public methods ////
	// Create an anonymous VMS user for unauthenticated playback.
	// The user favorites should be retained for all signed out user until they clear it manually.
	createAnonymousUser = async () => {
		this.authStatus = authStatuses.LOGGED_OUT;

		await VmsRealtimeService.createAnonymousUser(
			process.env.REACT_APP_VMS_APP_ID
		);
		//initiate to get the continue watching and favourites
		await window.CorusJwplayer.VmsRealtimeService.userFavorites(localStorage.getItem('puid'), 0);
		await window.CorusJwplayer.VmsRealtimeService.userHistory(localStorage.getItem('puid'), 0,watchHistoryDurationInDays);
		this.context.updateIfChanged('isVmsAuthInProgress', false);
	}

	// Create an authenticated VMS user using Adobe ID and credentials.
	createAuthenticatedUser = async () => {
		this.authStatus = authStatuses.LOGGED_IN;
		await VmsRealtimeService.createAdobeAuthenticatedUser(
			process.env.REACT_APP_VMS_APP_ID,
			process.env.REACT_APP_VMS_DISTRIBUTION_ID,
			this.context.authZToken,
			this.context.authorizedResources,
			this.context.adobeUid
		);


		// To make a authenticate user to initate the api for user/history call and get the continue watching
		await window.CorusJwplayer.VmsRealtimeService.userHistory(localStorage.getItem('puid'), 0, watchHistoryDurationInDays);
		await window.CorusJwplayer.VmsRealtimeService.userFavorites(localStorage.getItem('puid'), 0);

		this.context.updateIfChanged('isVmsAuthInProgress', false);
	}

	logoutUser = () => {
		this.authStatus = authStatuses.LOGGED_OUT;

		VmsRealtimeService.deauthenticateUser();

		this.context.updateIfChanged('isVmsAuthInProgress', false);
	}

	render() {
		return null;
	}
}
