import React, { useState } from 'react';

import VideoTileArea from '../../widgets/tile-areas/VideoTileArea/VideoTileArea';
import './ContinueWatching.scss';
import UserContext from '../../contexts/UserContext';
import ClearWatchingModel from '../ClearContinueWatching/ClearWatchingModel';

// Returns one video per show, and limited to 12 videos
const continueWatchingFilter = (items) => {
	return items.filter((item, index, itemsArray) => {
		const show = item.data.show_title;
		return itemsArray.findIndex(item => item.data.show_title == show) === index;
	}).splice(0, 12);
}


const ContinueWatching = () => {
	const [clearModel, setClearModel] = useState(false);
	const { signedIn, authorizedResources } = React.useContext(UserContext);
	const loginState = signedIn && authorizedResources.length > 0;
	const type = ['episode','movie'];
	const items = window.CorusJwplayer.LocalStorageService.Instance.items;
	if (items.length === 0 ) {
		return null;
	}

	// Don't show completed videos and value zero and less than 1 one percentage and greaterthan 99 percent complete video
	let tiles = items.filter(item => item.isCompleted === false && item.value && item.percentage <= 99 && item.percentage >= 1 );

	if (tiles.length === 0 ) {
		return null;
	}

	// Sort from latest watched to oldest watched
	tiles = tiles.sort((a, b) => {
		return b.timestamp - a.timestamp;
	});



	let mediaIds = tiles.map(item => {
		return item.mediaId;
	});

	const triggerOverlay = () => {
		setClearModel(true);
	}

	const clearIcon = () => {
		return <div className="Close-icon-box" onClick={ triggerOverlay }>
			<span className="Close-icon">clear</span>
			<span className="Close-icon">x</span>
		</div>
	}

	const ClearContinueWatching = (
		<ClearWatchingModel state={loginState} closeModel={() => setClearModel(false)}/>
	)

	return (
		<div className="ContinueWatching">
			<VideoTileArea
				key="continueWatching"
				title="Continue Watching"
				order={2}
				limit={100}
				guid={mediaIds}
				type={type}
				expanded={true}
				closeIcon={clearIcon}
				resultsFilter={continueWatchingFilter}
			/>
			{ clearModel && ClearContinueWatching }
		</div>
	)
};

export default ContinueWatching;
