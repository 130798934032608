import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import UserContext from '../../contexts/UserContext';
import Auth from '../../../utils/Auth';
import { byOrigin as brands } from '../../../brands/brands';
import bdus from '../../../bdus/bdus'
import Spinner from '../Spinner/Spinner';
import { isCustomMsgNeeded, isLiveTVRoute, isVideoRoute, isPlayRoute, configbyAdobeID, getCookie } from '../../../utils/helpers';
import stackTVBanner from '../../../assets/icons/Stack TV Banner.png'
import stackTVBanner2X from '../../../assets/icons/Stack TV Banner@2x.png'
import './AuthModal.scss';

import arrowLeftImg from '../../../assets/icons/arrow_back.svg';

class AuthModal extends Component {

	static contextType = UserContext;

	state = {
		authAnalyticsCallSent: false,
		step: 1,
		isOverlayMessage: false,
	}

	componentDidMount() {
		this.sendAnalyticsCall();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.step !== this.state.step) {
			this.sendAnalyticsCall();
		}
	}

	closeAuthModal = () => {
		const modal = document.getElementsByClassName('AuthModal')[0];
		modal.classList.add('AuthModal-close-btn');

		setTimeout(() => {
			this.context.update('displayAuth', false);
		}, 300)

		this.context.update('pageRefresh', true);

		if (this.context.signedIn && (isVideoRoute(document.URL) || isLiveTVRoute(document.URL) || isPlayRoute(document.URL))) {
			setTimeout(() => {
				window.location.reload();
			}, 500);
		}
	}

	sendAnalyticsCall = () => {
		const consent = getCookie('consentCheck')
		if (consent !== 'declined') {

			if (this.state.step === 1 && !this.context.signedIn) {
				window.CorusAdobeAnalytics.trackLink(true, {
					'content.UserAction': 'authentication.flow|Step1 Select BDU',
					'authentication.flow': 'Step1 Select BDU',
				}, 'Step1 Select BDU');
			} else if (this.state.step === 2 && !this.context.signedIn) {
				window.CorusAdobeAnalytics.trackLink(true, {
					'content.UserAction': 'authentication.flow|Step2 Sign in Form',
					'authentication.flow': 'Step2 Sign in Form',
					'authentication.bdu': this.context.selectedBDU,
				}, 'Step2 Sign in Form');
			} else if (this.context.signedIn && !this.state.authAnalyticsCallSent) {
				window.CorusAdobeAnalytics.trackLink(true, {
					'content.UserAction': 'authentication.flow|Step3 Sign in successful',
					'authentication.flow': 'Step3 Sign in successful',
					'content.authentication': 'authenticated user',
					'authentication.bdu': this.context.selectedBDU,
					'adobe.passid': this.context.adobeUid
				}, 'Step3 Sign in successful')

				this.setState({ authAnalyticsCallSent: true })
			}
		}
	}
	AuthMessageModal = null;

	render() {

		let step = this.state.step;
		const isRokuActivatePage = window.location.pathname === '/roku/activate'
		if (this.context.signedIn && this.context.authorizedResourcesChecked) {
			step = 3;
			this.sendAnalyticsCall();
		}

		let unauthorizedResources = [];
		if (Auth.availableResources && this.context.authorizedResources) {
			unauthorizedResources = Auth.availableResources.sort((a, b) => configbyAdobeID(a).order - configbyAdobeID(b).order)
				.filter(resource => !this.context.authorizedResources.includes(resource));
		}
		const logoImage = (origin) => {
			let imgSrc;
			this.context.channelData.map(channel => {
				if (channel.data.origin_id === origin) {
					channel.resources.map(logo => {
						if (logo.tag === 'logo_origin_4colour') {
							imgSrc = logo.uri
						}
					})
				}
			})
			return imgSrc
		}

		const bduLoginFlow = (mvpd) => {
			Auth.setSelectedProvider(mvpd.ID);
			this.setState({ step: 2 })
		}

		return (
			ReactDOM.createPortal(
				<div className="AuthModal-wrapper">
					<div className={'AuthModal authStep-' + step}>
						<div className="AuthModal-header">
							<div className="AuthModal-header-top">
								<button onClick={() => this.closeAuthModal()} className="AuthModal-close button-wrapper">
									&times;
								</button>
								<button onClick={() => this.setState({ step: --step })} className="AuthModal-back">
									<img src={arrowLeftImg} alt="Back" /><span>Back</span>
								</button>
							</div>
							<div className="AuthModal-header-bottom">
								<span className="signin-title">Step {step} of 3</span>
								<span className="signin-title">Sign In</span>
							</div>
						</div>
						<div className="AuthModal-body">
							{step === 1 ? (
								<div className="bdu-picker">
									{
										this.context.callbackOpenSigninModal ? (
											<>
												<div className="auth-modal-text">
													<p className={'auth-modal-text--bolder'}>Sign in to start watching the very best of TV.</p>
													<p>Stream Global and the most watched channels in Canada including HGTV, Food&nbsp;Network, W&nbsp;Network and more.</p>
												</div>
												<div className="bdu-providers-container">
													<ul className="bdu-providers">
														{
															this.context.mvpds.map((mvpd) => {
																return (
																	<li id={mvpd.ID} key={mvpd.ID} onClick={() => { bduLoginFlow(mvpd) }}>
																		<div className="bdu-image">
																			<img src={mvpd.logoURL ? mvpd.logoURL : bdus[mvpd.ID].logoURL} alt={mvpd.displayName} />
																		</div>
																	</li>
																)
															})
														}
													</ul>
												</div>
												{
													<div className="auth-modal-footer">
														{!isRokuActivatePage ?
															<>
																<p>Looking for more ways to stream?</p>
																<a href='https://www.stacktv.ca/?utm_source=Corus&utm_medium=Website&utm_campaign=GTAauthenticator&utm_content=STACKTV-Link' target="_blank" rel="noopener noreferrer">
																	<img src={stackTVBanner} srcSet={stackTVBanner2X + ' 2x'} alt='Stack TV' />
																</a>
															</>
															:
															<>
																<p className='banner-rokuActivate'>Why isn&apos;t my TV Provider listed?</p>
																<p>We are frequently adding new Canadian TV providers. Please check back soon to see if we have added yours to the list.</p>
															</>}
													</div>}

											</>
										) : (
											<>
												Loading...
											</>
										)
									}
								</div>
							) : step === 2 ? (
								<div className="single-bdu">
									<div className="auth-modal-text">
										A new window will open to login with your TV provider credentials.<br />
										Please do not close this window as you authenticate with the new window.
									</div>
									{this.state.isOverlayMessage ? this.AuthMessageModal : <Spinner color="black" />}
								</div>
							)
								: step === 3 ? (
									<>
										<div className="auth-final-step">
											{
												this.context.authorizedResources.length > 0 ?
													(
														<>
															<div className="auth-modal-text">
																<p className={'auth-modal-text--bolder'}>You’ve successfully signed in.</p>
																<p>
																	{isCustomMsgNeeded(this.context.selectedBDU) ?
																		'Based on your TV Provider, you’ve got access to the following channels below.' :
																		'Based on your TV package, you have access to these channels:'
																	}
																</p>
															</div>
															<div className="auth-authorized-resources">
																<ul>
																	{
																		this.context.authorizedResources.map((authorizedResource) => {
																			const origin = configbyAdobeID(authorizedResource).origin;
																			// Render resource logo only if origin is available from the VMS configuration
																			return (
																				origin && <li
																					key={authorizedResource}
																					className={
																						'resource-logo' +
																						' resource-logo--' + (authorizedResource) +
																						((this.context.authorizedResources.length > 4 && unauthorizedResources.length > 0) ?
																							' resource-logo--small' :
																							''
																						)
																					}
																				>
																					<img src={logoImage(origin) || brands(origin).images.coloured} alt={authorizedResource} />
																				</li>
																			)
																		})
																	}
																</ul>
															</div>
														</>
													) : null
											}
											<div className='auth-selection'>
												<button className="AuthModal-ok-button button-primary" onClick={() => this.closeAuthModal()}>OK</button>
												<p>Content availability may vary by TV provider. </p>
											</div>
										</div>

										{
											unauthorizedResources.length > 0 ?
												(
													<>
														{
															this.context.authorizedResources.length === 0 ?
																<div className="auth-modal-text">
																	Oops. Looks like you don&apos;t have access to these channels. Please contact your provider for more details.
																</div> :
																<div className="unauthorized-cta">
																	{isCustomMsgNeeded(this.context.selectedBDU) ?
																		'Unfortunately, your TV Provider has made a decision that prevents subscribers from unlocking the additional content via the Global TV app. We appreciate your feedback and encourage you to share your feedback with your service provider directly. Note that you will be able to access the Global TV and History live-stream and on-demand with your credentials.'
																		:
																		this.context.authorizedResources.length === 1 && this.context.authorizedResources[0] === 'globalnews' ?
																			'It appears you do not currently have access to any channels via your TV provider. This may be due to an issue with your account. Please contact your TV provider for information.' :
																			!isRokuActivatePage && <div className="auth-modal-footer">
																				<p>Looking for more ways to stream?</p>
																				<a href='https://www.stacktv.ca/?utm_source=Corus&utm_medium=Website&utm_campaign=GTAauthenticator&utm_content=STACKTV-Link' target="_blank" rel="noopener noreferrer">
																					<img src={stackTVBanner} srcSet={stackTVBanner2X + ' 2x'} alt='Stack TV' />
																				</a>
																			</div>
																	}
																</div>
														}
													</>
												) :
												!isRokuActivatePage && <div className="auth-modal-footer">
													<p>Looking for more ways to stream?</p>
													<a href='https://www.stacktv.ca/?utm_source=Corus&utm_medium=Website&utm_campaign=GTAauthenticator&utm_content=STACKTV-Link' target="_blank" rel="noopener noreferrer">
														<img src={stackTVBanner} srcSet={stackTVBanner2X + ' 2x'} alt='Stack TV' />
													</a>
												</div>
										}
									</>

								) : null
							}
						</div>
					</div>
				</div>,
				document.getElementById('root'),
			)
		);
	}
}

export default AuthModal;
