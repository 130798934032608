import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import UserContext from '../../../contexts/UserContext';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { makingTrackingCall } from '../../../../utils/helpers';

import './HeroContent.scss';
import LogoImageFromVMS from '../../../utility/logoImageFromVMS';

class HeroContent extends Component {
	static propTypes = {
		showBrandImage: PropTypes.string,
		showBrandName: PropTypes.string,
		showDesc: PropTypes.string,
		showId: PropTypes.string,
		showName: PropTypes.string,
		origin: PropTypes.string,
		history: PropTypes.object.isRequired,
		contentListing: PropTypes.string,
		airingNetwork: PropTypes.string,
		rotatorType: PropTypes.string,
		ctaLink: PropTypes.object,
		tags: PropTypes.array,
		type: PropTypes.string,
		order: PropTypes.number,
		title: PropTypes.string,
		dotSelected: PropTypes.number,
	}

	static contextType = UserContext;

	updateRoute = (showName, showOrigin, showId, contentListing, airingNetwork, type ) => {
		this.analyticsCarouselPick(showName);

		if ( type !== 'channel' && contentListing !== 'livestream' && contentListing !== 'promo' ) {
			return this.props.history.push(`/series/${showId}`);
		} else {
			return this.props.history.push(`/channel/${showId}`);
		}
	}

	analyticsCarouselPick = (showName) => {
		const { signedIn, authorizedResources, selectedBDU, adobeUid } = this.context;
		const loginState = signedIn && authorizedResources.length > 0;
		return makingTrackingCall(
			`${this.props.order}|${this.props.title}|${this.props.dotSelected}|${showName}`,
			'HeroCarouselPick',
			loginState,
			selectedBDU,
			adobeUid
		);
	}

	/**
	 * Get the Click to Action button text
	 * @param {string} rotatorType Rotator type passed from Hero using tags
	 * @param {array} tags array from API request
	 * @return {string}
	 */
	ctaText = ( rotatorType = '', tags = [] ) => {
		var btnString = 'Watch Now';
		if ( rotatorType ) {
			if ( rotatorType === 'link' ) {
				btnString = 'See More';
			}
		}

		if ( tags ) {
			tags.forEach( ( tag ) => {
				if ( tag.type === 'container_button_decorator' ) {
					btnString = tag.label;
				}
			} )
		}
		return btnString;
	}

	render() {
		const {  showDesc, showId, showName, origin: showOrigin, contentListing, airingNetwork, rotatorType, ctaLink, tags, type } = this.props;
		return (
			<TransitionGroup component="div" className="HeroContent">
				<CSSTransition
					classNames="HeroContent"
					key={this.props.showName}
					timeout={{ enter: 1000, exit: 0 }}
				>
					<div className="Hero-content-wrapper">
						<LogoImageFromVMS origin ={showOrigin} classes ={'Hero-brand-logo Hero-brand-logo-' + showOrigin} />
						<h2 className="Hero-show-name">{showName}</h2>
						<p className="Hero-show-desc">{showDesc}</p>
						{
							/**
							 * if CTA link is set in Hero.js, use link else use default route path
							 */
							ctaLink ? <a className="Hero-watch-button button-primary" target="_blank" rel="noopener noreferrer" href={ctaLink.uri}>{this.ctaText( rotatorType, tags )}</a>
								: <button className="Hero-watch-button button-primary" onClick={() => this.updateRoute(showName, showOrigin, showId, contentListing, airingNetwork, type)}>{this.ctaText( rotatorType, tags )}</button>
						}
					</div>
				</CSSTransition>
			</TransitionGroup>
		)
	}
}

export default withRouter(HeroContent);