import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { addingPermutiveValues } from '../../../../utils/permutiveHelper';

import fromVms from '../../../utility/fromVms';

/**
 * Get child collection of given GUID.
 */
class ChildCollections extends Component {
	static propTypes = {
		brands: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.string,
		]),
		guid: PropTypes.string,
		items: PropTypes.array,
		loading: PropTypes.bool,
		ordering: PropTypes.string,
		type: PropTypes.string,
		onCollectionClick: PropTypes.func,
		activeCollection: PropTypes.string,
		seasonId: PropTypes.string,
		collectionId: PropTypes.string,
		title: PropTypes.string,
		order: PropTypes.string
	};

	state = {
		activeCollection: false,
	}


	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps) {
		if (this.props.items.length > 0 && prevProps.items !== this.props.items) {
			this.props.onCollectionClick(this.props.items[0].guid, this.props.items[0].data.default_ordering || '-reference_date');
		}
	}

	checkAnalytics(item) {
		const { signedIn, authorizedResources, selectedBDU } = this.context;
		const loginState = signedIn && authorizedResources.length > 0;
		const currentPage = localStorage.getItem('currentPage');
		const sections = [currentPage, this.props.items[0].origin, item.data?.description, item.label];
		addingPermutiveValues({
			content: {
				...(sections.every((item) => typeof item === 'string') ? { sections } : ''),
				type: currentPage,
				name: item.data?.description || item.data?.show_title,
				keywords: item.data.keywords?.split('/')
			},
			user: {
				auth: {
					status: loginState ? 'authenticated' : 'non-authenticated',
					...(loginState && { bdu: selectedBDU })
				}
			}
		})
	}

	render() {
		if (!this.props.loading && (!this.props.items || !this.props.items.length)) {
			return null;
		}
		var childCollections = [];
		childCollections = this.props.items.map((item, index) => {
			let ordering = item.data.default_ordering || '-reference_date';

			// Special case when the smartplay episode season is not published then show the episodes of the next available latest season

			if (this.props.type !== 'collection' && !this.props.seasonId) {
				this.props.onCollectionClick(this.props.items[0].guid, ordering);
			}

			return (
				<li
					className={this.state.activeCollection === item.guid || this.props.collectionId === item.guid || this.props.seasonId === item.guid ? 'active' : undefined}
					key={index}
					onClick={
						() => {
							window.history.pushState('', '', `/series/${this.props.guid}/collection/${item.guid}/`);
							this.props.onCollectionClick(item.guid, ordering);
							this.setState({ activeCollection: item.guid })
							this.checkAnalytics(item)
						}
					}
				>{item.label}</li>
			)
		});

		return (
			<div className="child-collections-wrapper">
				<div className={this.props.collectionId ? 'child-collections' : 'child-seasons'}>
					<ul className="inline-buttons">
						{childCollections}
					</ul>
				</div>
			</div>
		)
	}
}

const propMapping = ({ guid, limit, ordering, type }) => {
	return {
		ordering: ordering ? ordering : 'order',
		parent: guid,
		type,
		limit: limit ? limit : 30,
		'tags.value': 'availability_application_globalstream',
	}
}

export default fromVms(ChildCollections, propMapping);
