import PropTypes from 'prop-types';
import React, { Component } from 'react';
import UserContext from '../../../contexts/UserContext';
import './ShowTile.scss';
import { byOrigin as brands } from '../../../../brands/brands';
import { optimizeImage, validateAuthorization } from '../../../../utils/helpers';

class ShowTile extends Component {

	static contextType = UserContext;

	static propTypes = {
		classModifiers: PropTypes.array,
		delay: PropTypes.number,
		label: PropTypes.string,
		loading: PropTypes.bool,
		isFree: PropTypes.bool,
		origin: PropTypes.string,
		resources: PropTypes.array,
		thumbnail: PropTypes.string,
		onClick: PropTypes.func,
		tags: PropTypes.array,
		data: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.object,
		]),
	};

	static defaultProps = {
		classModifiers: []
	}

	showTileLoaderTimeOut = null;

	state = {
		hideLoader: false,
		showImage: false
	};

	componentDidMount() {
		let delay = !this.props.delay ? 2000 : this.props.delay;

		this.showTileLoaderTimeOut = setTimeout(() => {
			this.setState({ hideLoader: true });
		}, delay);
	}

	componentWillUnmount() {
		// Clear pending subscriptions/tasks
		clearTimeout(this.showTileLoaderTimeOut)
	}

	handleShowImage = () => {
		this.setState({ showImage: true })
	}

	render() {
		const { data: { subtype }, isFree } = this.props;
		const { configData, authorizedResources, signedIn } = this.context;
		const brand = brands(this.props.origin);
		const placeholderImage = brand ? brand.placeholderImages.showVertical : '';
		const applicableImages = this.props.resources.filter((resource) => resource.tag === 'show_vertical');
		const showImage = (applicableImages.length && this.state.showImage) ? optimizeImage(applicableImages[0].uri, '250') : placeholderImage;

		let marketingDecor = this.props.tags.find(item => item.type === 'marketing_decorator');
		const isAuth = validateAuthorization(this.props.origin, authorizedResources);
		const removeMarketIndicator = configData?.configuration?.entries?.application?.excludedDecoratorsForAuthUsers.includes(marketingDecor?.value);

		if ((isFree && removeMarketIndicator) || (isAuth && signedIn && removeMarketIndicator))
			marketingDecor = null;

		let showCaption = marketingDecor && <div className="ShowTile-caption">{marketingDecor.label}</div>;

		const tileContents = (
			<>
				{showCaption}
				<img className="ShowTile-image" src={showImage} alt={this.props.label} onLoad={() => this.handleShowImage()} />
			</>
		)

		let classModifiers = this.props.classModifiers.reduce((string, modifier) => {
			return string + ' ShowTile--' + modifier
		}, '');
		const className = 'ShowTile' + classModifiers;

		/**
		 * Set the view more tile
		 */
		if (subtype) {
			/**
			 * Find resource link
			 */
			const viewMoreLink = this.props.resources.find(resource => {
				return resource.type === 'link';
			});

			if (viewMoreLink && viewMoreLink.uri) {
				// const link = viewMoreLink.uri.replace(/^\/?([^\/]+(?:\/[^\/]+)*)\/?$/, '/$1/');
				const link = viewMoreLink.uri;
				return (
					<a className="ShowTile view-more" href={link}>
						<div className="icon-box">
							<span className="chevron-right"></span>
						</div>
						<div className="ShowTile-image ShowTile-text">
							VIEW MORE
						</div>
					</a>
				);
			}
			return '';
		}

		return (
			<div className={className + ((this.state.hideLoader === false || this.props.loading) ? ' ShowTile-loader' : ' ')} onClick={this.props.onClick}>
				{tileContents}
			</div>
		)
	}
}

export default ShowTile;
