import UserContext from '../components/contexts/UserContext';
import { setCookie } from './helpers';
import { queryVms } from './vmsQuery';

// Authentication utility object.
// Functions suffixed with "callback" will be referenced by the window, and
//  called by Adobe in response to certain async authentication events.
class Auth {
	// Adobe Access Enabler API instance.
	accessEnablerAPI = null;

	// Adobe content authZ token.
	authorizationToken = null;

	// Callback function that accepts two properties - the property to update, and the new value.
	contextUpdate = null;

	// Array of XML MVPD nodes.
	mvpdsXML = null;

	// Whether the signed in status has been loaded or not.
	ready = false;

	// Cached sign in status for videoplayer usage.
	signedIn = false;

	// References to any site object subscribers for event callbacks (i.e. video players) in { eventName: [ subscribers ] } format.
	subscribers = {};

	availableResources = [];
	authorizedResources = [];

	authCookieExpiry = 30;
	bduNameCookieKey = 'authName';
	authenticatedFlagCookieKey = 'authN';
	trackingIdKey = 'authTrackingId';

	LocalStorageService = window.CorusJwplayer.LocalStorageService;
	static contextType = UserContext

	constructor() {
		this.availableResources = process.env.REACT_APP_AUTH_AVAILABLE_RESOURCES.split(',');

		const scriptPromise = new Promise((resolve, reject) => {
			const script = document.createElement('script');
			document.head.appendChild(script);
			script.onload = resolve;
			script.onerror = reject;
			script.async = true;
			script.src = process.env.REACT_APP_ADOBE_ACCESS_ENABLER_URL;
		});

		scriptPromise.then(() => {
			this.accessEnablerAPI = new window.Adobe.AccessEnabler(process.env.REACT_APP_ADOBE_SOFTWARE_STATEMENT);
			this.getAuthentication();
		});
	}

	/**** Functions to be called by the site or site components. ****/

	getAuthentication = () => {
		this.accessEnablerAPI.getAuthentication();
	}

	getAuthorization = (resource = 'globaltv') => {
		// TODO: this should pass the resource ID of the current video
		// The problem is that this function is called by the player and the player has no concept of
		//   dynamic resource IDs, so we need some additional logic.
		// An alternative is to bypass requiring Auth if the user is logged in since VMS doesn't check
		//   the token for now.
		// There also seems to be a timing constraint that this function is sometimes called before
		//   adobeAuth is ready if the player page is loaded directly, causing it to fail.
		this.accessEnablerAPI.getAuthorization(resource);
	}

	getMetadata = (key) => {
		this.accessEnablerAPI.getMetadata(key);
	}

	isAuthenticatedUser = () => {
		var promise = new Promise((resolve) => {
			var checkReady = () => {
				// Check if the access enabler API has finished loading.
				if (this.ready) {
					this.debugLog('isAuthenticatedUser, authN= ' + this.signedIn);
					resolve(this.signedIn);
				} else {
					setTimeout(checkReady, 200);
				}
			};

			checkReady();
		});

		return promise;
	}

	setSelectedProvider = (mvpdID) => {
		const mvpdConfig = this.getMVPDConfigByID(mvpdID);

		let forceIframe = mvpdConfig.querySelector('iFrameRequired') ? JSON.parse(mvpdConfig.querySelector('iFrameRequired').textContent) : false;
		let windowWidth = mvpdConfig.querySelector('iFrameWidth') ? mvpdConfig.querySelector('iFrameWidth').textContent : 500;
		let windowHeight = mvpdConfig.querySelector('iFrameHeight') ? mvpdConfig.querySelector('iFrameHeight').textContent : 500;

		if (forceIframe) {
			window.open('', 'mvpdframe', 'width=' + windowWidth + ',height=' + windowHeight + ',top=t,left=l');
		} else {
			window.open('', 'mvpdwindow', 'width=' + windowWidth + ',height=' + windowHeight + ',top=t,left=l');
		}

		this.accessEnablerAPI.setSelectedProvider(mvpdID);
	}

	signOut = () => {
		this.LocalStorageService.Instance.deleteDataById('items'); // delete binge watched item from local storage in case of conflict
		this.LocalStorageService.Instance.deleteDataById('favorites');
		this.LocalStorageService.Instance.deleteDataById('puid');
		this.accessEnablerAPI.logout();
		this.contextUpdate('signedIn', false);
		this.authorizedResources = [];
		this.contextUpdate('authorizedResources', []);
		this.contextUpdate('adobeUid', false);
		setCookie(this.authenticatedFlagCookieKey, '', -1);
		setCookie(this.bduNameCookieKey, '', -1);
		//Clear the localstorage when make a signout for all users
		this.LocalStorageService.Instance.removeAll();
		this.LocalStorageService.Instance.save();
		localStorage.removeItem('selectedBDU');
		sessionStorage.removeItem('isEastlinkDialogShown');
		this.contextUpdate('pageRefresh', true)
	}

	/**** Adobe callbacks. ****/

	entitlementLoadedCallback = () => {
		this.debugLog('entitlementLoaded');

		this.accessEnablerAPI.setRequestor('globaltv', null, {
			backgroundLogin: true,
		});

		this.accessEnablerAPI.checkAuthentication();
	}

	setAuthenticationStatusCallback = (status, errorcode) => {
		if (typeof status === 'string') {
			status = parseInt(status);
		}
		if (status === 1) {
			this.signedIn = true;
			this.contextUpdate('signedIn', true);
			setCookie(this.authenticatedFlagCookieKey, '1', this.authCookieExpiry);
			this.accessEnablerAPI.checkPreauthorizedResources(this.availableResources);
			this.accessEnablerAPI.getSelectedProvider();
			this.getMetadata('userID');
		} else {
			this.contextUpdate('adobeUid', false);
		}
		this.ready = true;
		this.contextUpdate('isAuthInProgress', false);

		this.debugLog('setAuthenticationStatus', status, errorcode);
	}

	selectedProviderCallback = (result) => {
		if (result) {
			this.contextUpdate('selectedBDU', result.MVPD)
			localStorage.setItem('selectedBDU', result.MVPD);
			setCookie(this.bduNameCookieKey, result.MVPD, this.authCookieExpiry);
		}
	}

	sendTrackingDataCallback = (event, data) => {
		this.debugLog('sendTrackingData', event, data);
		if (event === 'authenticationDetection' && data[0]) {
			this.contextUpdate(this.trackingIdKey, data[2]);
		}
	}

	preauthorizedResourcesCallback = (resources) => {

		// Sort the channels for Alphabetized Logos
		this.authorizedResources = resources.sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) });
		let index = this.authorizedResources.findIndex(channel => channel === 'globaltv')
		if (!this.authorizedResources.includes('globalnews')) {
			this.authorizedResources.splice(index + 1, 0, 'globalnews');
		}
		this.contextUpdate('authorizedResources', this.authorizedResources);
		this.contextUpdate('authorizedResourcesChecked', true);
		this.debugLog('preauthorizedResources', resources);

		if (!resources) {
			this.debugLog('no resources');
		}
	}

	displayProviderDialogCallback = async (mvpds) => {
		try {
			/**Below if condition to check the bdu view id value is existing in the mobile browser bcz the mobile
			 browser we don't have view call and did not show up normal app for that activation page needs bdu provider
			 list in the overlay
			**/
			if (!localStorage.getItem('BDU_VIEW_ID')) {
				try {
					const bduView = await queryVms({
						type: 'view',
						limit: 10,
						parent: process.env.REACT_APP_VMS_DISTRIBUTION_ID
					});
					bduView.results.forEach(item => {
						//Take out the bdu ID from the view call
						if (item.data['view_id'] === 'bdu') {
							localStorage.setItem('BDU_VIEW_ID', item.guid);
						}
					});
				} catch (e) {
					console.log(e);
				}
			}
			const bduData = await queryVms({
				limit: 99,
				parent: localStorage.getItem('BDU_VIEW_ID'),
			});
			// Sorting the order and creating MVPDS.
			this.mvpds = [];
			bduData['results'].sort((a, b) => a.order - b.order).forEach(providers => {
				const dataURI = providers.resources.filter((resource) => resource.tag === 'thumbnail_small');
				const { uri: logoURL } = (dataURI.length > 0) ? dataURI[0] : null;
				const { data, data: { mso_id: ID, name: displayName } } = providers;
				const mvpdData = mvpds?.find(mvpd => mvpd.ID === ID);
				if (mvpdData) {
					this.mvpds.push({ logoURL, displayName, ID, data });
				}
			});
			this.contextUpdate('mvpds', this.mvpds);
			this.contextUpdate('callbackOpenSigninModal', true);

		} catch (e) {
			console.log(e);
		}
	}

	setConfigCallback = (configXML) => {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(configXML, 'text/xml');
		this.mvpdsXML = xmlDoc.querySelectorAll('requestor mvpds mvpd');
	}

	createIFrameCallback = () => {

	}

	destroyIFrameCallback = () => {

	}

	setTokenCallback = (resource, token) => {
		this.debugLog('setToken', resource, token);
		this.contextUpdate('authZToken', token);
		this.fire('setToken', token);
	}

	tokenRequestFailedCallback = (resource, code, description) => {
		this.debugLog('tokenRequestFailed', resource, code, description);
		this.contextUpdate('authZToken', '-1');
		this.fire('tokenRequestFailed', resource, code, description);
	}

	setMetadataStatusCallback = (key, encrypted, data) => {
		if (key === 'userID' || key === 'upstreamUserID') {
			if (data === null) {
				this.contextUpdate('adobeUid', false);
			} else {
				this.contextUpdate('adobeUid', data);
			}
		}
	}


	/**** Subscription functions. ****/

	on = (eventName, callback) => {
		if (this.subscribers[eventName]) {
			this.subscribers[eventName].push(callback);
		} else {
			this.subscribers[eventName] = [callback];
		}
	}

	fire = (eventName, ...args) => {
		if (this.subscribers[eventName]) {
			this.subscribers[eventName].forEach(callback => {
				try {
					callback(...args);
				} catch (e) {
					console.log(e);
				}
			});
		}
	}

	off = (eventName, callback) => {
		if (this.subscribers[eventName]) {
			const subscriberIndex = this.subscribers[eventName].indexOf(callback);
			if (subscriberIndex !== -1) {
				this.subscribers[eventName].splice(subscriberIndex, 1);
			}
		}
	}

	/**** Utility functions. ****/

	getMVPDConfigByID = (mvpdID) => {
		let mvpdConfig = null;
		this.mvpdsXML.forEach((node) => {
			if (node.querySelector('id').textContent === mvpdID) {
				mvpdConfig = node;
			}
		});

		return mvpdConfig;
	}

	getChannelLogo = async (data) => {
		let channelGuids = []
		for (let key in data) {
			channelGuids.push(data[key].guid)
		}
		const channel = await queryVms({
			guid: channelGuids,
			limit: 25
		});
		this.contextUpdate('channelData', channel.results)
	}

	getConfiguration = async () => {
		const data = await window.CorusJwplayer.VmsRealtimeService.configuration(
			process.env.REACT_APP_VMS_APP_ID,
			process.env.REACT_APP_VMS_DISTRIBUTION_ID,
			'1.0.4');  // update the configuration for VMS
		this.getChannelLogo(data.origins)
		this.contextUpdate('configData', data);
	}

	debugLog = (...args) => {
		if (process.env.NODE_ENV === 'development') {
			console.log(...args);
		}
	}
}

const AuthInstance = new Auth();

// Add hooks required for Adobe to the window.
window.entitlementLoaded = AuthInstance.entitlementLoadedCallback;
window.setAuthenticationStatus = AuthInstance.setAuthenticationStatusCallback;
window.sendTrackingData = AuthInstance.sendTrackingDataCallback;
window.preauthorizedResources = AuthInstance.preauthorizedResourcesCallback;
window.displayProviderDialog = AuthInstance.displayProviderDialogCallback;
window.createIFrame = AuthInstance.createIFrameCallback;
window.setConfig = AuthInstance.setConfigCallback;
window.destroyIFrame = AuthInstance.destroyIFrameCallback;
window.setToken = AuthInstance.setTokenCallback;
window.tokenRequestFailed = AuthInstance.tokenRequestFailedCallback;
window.selectedProvider = AuthInstance.selectedProviderCallback;
window.setMetadataStatus = AuthInstance.setMetadataStatusCallback;


class VideoAuth {
	// Subscribers are limited to only the most recent video player to prevent runaway references
	// TODO: see if there's a way to unsubscribe when the player is unmounted

	setTokenCallback = null;
	tokenRequestFailedCallback = null;

	setCallback(callbacks) {
		if (this.setTokenCallback) {
			AuthInstance.off('setToken', this.setTokenCallback);
			AuthInstance.off('tokenRequestFailed', this.tokenRequestFailedCallback);
		}

		this.setTokenCallback = (token) => {
			callbacks.setTokenCallback.call(callbacks.caller, token)
		};

		this.tokenRequestFailedCallback = () => {
			callbacks.tokenRequestFailedCallback.call(callbacks.caller)
		};

		AuthInstance.on('setToken', this.setTokenCallback);
		AuthInstance.on('tokenRequestFailed', this.tokenRequestFailedCallback);
	}

	isAuthenticatedUser() {
		return AuthInstance.isAuthenticatedUser();
	}

	getAuthorization() {
		AuthInstance.getAuthorization();
	}

	setRedirectUrl() {
		AuthInstance.contextUpdate('displayAuth', true);
	}
}

export const VideoAuthInstance = new VideoAuth();

export default AuthInstance;
