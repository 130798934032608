import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fromVms from '../../../utility/fromVms';
import GridTileArea from '../GridTileArea/GridTileArea';
import ShowSection from '../../../partials/ShowSection/ShowSection';
import { ShowTile } from '../../tiles';
import TileSlider from '../../slider/TileSlider/TileSlider';
import UserContext from '../../../contexts/UserContext';

import './ShowTileArea.scss';
import { addingPermutiveValues } from '../../../../utils/permutiveHelper';
import { makingTrackingCall } from '../../../../utils/helpers';

class ShowTileArea extends Component {
	state = {
		tileSelected: 0,
		expandedItem: null,
		closingShowSection: false,
		brand: '',
	}

	static contextType = UserContext;

	// Keep a reference to tile areas on the page so that they can update others' state.
	static instances = [];

	static propTypes = {
		brands: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.string
		]),
		count: PropTypes.number,
		order: PropTypes.number,
		delay: PropTypes.number,
		items: PropTypes.array,
		loading: PropTypes.bool,
		isFree: PropTypes.bool,
		ordering: PropTypes.string,
		title: PropTypes.string,
		isGrid: PropTypes.bool,
		enableCTA: PropTypes.bool,
		searchKey: PropTypes.string,
		sortByLatest: PropTypes.bool,
		origin: PropTypes.string,
		offSet: PropTypes.number,
		selectedGenre: PropTypes.string,
	};

	static defaultProps = {
		brands: [],
		count: 12,
	}

	static gridColumns = {
		xs: 3,
		sm: 4,
		md: 5,
		lg: 6,
	};

	constructor(props) {
		super(props);
		this.tileSliderRef = React.createRef();
	}

	componentDidMount() {
		ShowTileArea.instances.push(this);
	}

	componentWillUnmount() {
		var index = ShowTileArea.instances.indexOf(this);
		if (index !== -1) ShowTileArea.instances.splice(index, 1);
	}

	componentDidUpdate(prevProps) {
		// Close show section under certain conditions
		if (typeof this.props.brands == 'string' && this.props.brands !== prevProps.brands || // If on a shows page and switched to a different brand
			this.props.searchKey !== prevProps.searchKey) { // If a new search was initiated
			this.handleOnClickClose();
			if (this.tileSliderRef.current) {
				this.tileSliderRef.current.resetSlider();
			}
		}
	}

	permutiveForShowsPage() {

		let currentPage = localStorage.getItem('currentPage')
		const { signedIn, authorizedResources, selectedBDU } = this.context;
		const loginState = signedIn && authorizedResources.length > 0;
		const sections = [currentPage, this.props.items[0].origin];

		// Send analytics call for only for shows page
		if (!this.props.isFree && this.state.brand !== this.props.items[0].origin) {
			addingPermutiveValues({
				content: {
					...(sections.every((item) => typeof item === 'string') ? { sections } : ''),
					type: currentPage
				},
				user: {
					auth: {
						status: loginState ? 'authenticated' : 'non-authenticated',
						...(loginState && { bdu: selectedBDU })
					}
				}
			});
			this.setState({
				brand: this.props.items[0].origin
			})
		}

	}

	checkAnalytics(itemData) {
		const { signedIn, authorizedResources, selectedBDU, adobeUid, isHome } = this.context;
		const loginState = signedIn && authorizedResources.length > 0;
		const currentPage = localStorage.getItem('currentPage');
		const collection = this.props.isFree ? this.props.selectedGenre : itemData.origin
		const sections = [currentPage, collection, itemData.label];
		if (this.props.title) {
			let order = localStorage.getItem('CW') && isHome ? this.props.order + 1 : this.props.order;
			//From home page
			addingPermutiveValues({
				content: {
					type: currentPage,
					name: itemData.label,
					keywords: itemData.data.keywords?.split(',')
				},
				user: {
					auth: {
						status: loginState ? 'authenticated' : 'non-authenticated',
						...(loginState && { bdu: selectedBDU })
					}
				}
			})
			return makingTrackingCall(
				/* For show tile page_name | collection_position | collection_title | position_in_collection | showTitle */
				`${order}|${this.props.title}|${itemData.order}|${itemData.label}`,
				'ShowTileArea',
				loginState,
				selectedBDU,
				adobeUid
			);
		} else {
			//From shows page
			addingPermutiveValues({
				content: {
					...(sections.every((item) => typeof item === 'string') ? { sections } : ''),
					type: currentPage,
					name: itemData.label,
					keywords: itemData.data.keywords?.split(',')
				},
				user: {
					auth: {
						status: loginState ? 'authenticated' : 'non-authenticated',
						...(loginState && { bdu: selectedBDU })
					}
				}
			})
		}
	}

	setExpandedItem(itemId, itemData) {
		if (itemData.type === 'series') {
			/**
			 * Just update the URL without going through router
			 */
			window.history.pushState('', '', `/series/${itemData.guid}`);
		}
		if (itemData.type === 'movie') {
			/**
			 * Just update the URL without going through router
			 */
			window.history.pushState('', '', `/movie/${itemData.guid}`);
		}
		// Don't open show section if the pointer was dragged or swiped
		ShowTileArea.instances.forEach(instance => {
			instance.setState({
				expandedItem: instance === this ? itemId : null,
			});
		});
		this.checkAnalytics(itemData);
	}

	handleOnClickClose() {
		// Trigger the closing animation
		this.setState({
			closingShowSection: true,
		});

		// Delay to allow animation, then remove show section and reset closing flag
		setTimeout(() => {
			this.setState({
				expandedItem: null,
				closingShowSection: false,
			})
		}, 400);
	}

	renderTiles() {
		let { items, ordering, isFree } = this.props;
		// set default sort to VMS Curated Order
		if (!ordering) {
			items = items.sort((a, b) => (a.order - b.order));
		}

		let tiles = items.map(itemData => {
			const isSelected = this.state.expandedItem === itemData.guid;
			const tileProps = {
				classModifiers: isSelected ? ['selected'] : [],
				onClick: () => { this.setExpandedItem(itemData.guid, itemData) },
				...itemData
			};

			return <ShowTile key={itemData.guid} {...tileProps} isFree={isFree} />;

		});

		return tiles;
	}

	renderTileSlider() {
		const tiles = this.renderTiles();
		const expandedItemData = this.props.items.find(item => {
			return item.guid === this.state.expandedItem;
		});
		const marketingDecor = this.props.items.find(item => {
			return item.tags && item.tags.find(tag => {
				return tag.type === 'marketing_decorator';
			});
		});

		const tileSliderProps = {}

		if (marketingDecor) {
			tileSliderProps.marketingDecor = true;
		}

		return (
			<>
				<TileSlider {...tileSliderProps} items={tiles} gridColumns={ShowTileArea.gridColumns} ref={this.tileSliderRef} />
				{expandedItemData &&
					<ShowSection
						{...expandedItemData}
						closeShowSection={() => this.handleOnClickClose()}
						key={expandedItemData.guid}
						scrollIntoView={true}
						closing={this.state.closingShowSection}
					/>
				}
			</>
		);
	}

	renderTileGrid() {
		const tiles = this.renderTiles();
		this.permutiveForShowsPage();
		const expandedItemData = tiles.find(item => {
			return item.props.guid === this.state.expandedItem;
		});
		if (expandedItemData) {
			const insertIndex = tiles.indexOf(expandedItemData) + 1;
			const showSection = <ShowSection
				{...expandedItemData.props}
				closeShowSection={() => this.handleOnClickClose()}
				key={`ShowSection-${expandedItemData.props.guid}`}
				scrollIntoView={true}
				closing={this.state.closingShowSection}
			/>
			tiles.splice(insertIndex, 0, showSection);
		}

		return (
			<GridTileArea items={tiles} offSet={this.props.offSet} loading={this.props.loading} />
		);
	}

	render() {
		// check for items length is needed to hide all brand sliders with zero search results in the search interfece
		if (this.props.loading || this.props.items.length < 1) return null;

		return (
			<div className="ShowTileArea">
				{this.props.title && <h3 className="ShowTileArea-title">{this.props.title}</h3>}
				{this.props.isGrid === true
					? this.renderTileGrid()
					: this.renderTileSlider()
				}
			</div>
		)
	}
}

const propMapping = ({ ordering, parentId, searchKey, origins }) => {
	if (searchKey) {
		return {
			search_filter: '297aead2-e8b9-11eb-b209-4ed2a20f5c5e',
			search_filter_query: searchKey.toLowerCase(),
			type: ['series', 'movie'],
			origin: origins,
			limit: 100,
			'tags.value': 'availability_application_globalstream',
			ordering: 'label',
		}

	} else {
		return {
			ordering: ordering || 'order',
			parent: parentId,
			type: ['movie', 'series', 'document'],
		}
	}
}

export default fromVms(ShowTileArea, propMapping);
