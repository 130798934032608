import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import fromVms from '../../utility/fromVms';
import brands from '../../../brands/brands';
import './BrandBar.scss';
import { makingTrackingCall } from '../../../utils/helpers';
import UserContext from '../../contexts/UserContext';
import TileSlider from '../../widgets/slider/TileSlider/TileSlider';

class BrandBar extends Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.brandSliderRef = React.createRef();
	}

	static gridColumns = {
		xs: 4,
		sm: 5,
		mm: 6,
		md: 7,
		lg: 8,
		xm: 9,
		xl: 10
	};


	static propTypes = {
		brands: PropTypes.array,
		order: PropTypes.string,
		orderNumber: PropTypes.number,
		title: PropTypes.string,
		selectedBrand: PropTypes.string,
		selectedBrandCollectionId: PropTypes.string,
		urlPrefix: PropTypes.string,
		parentId: PropTypes.string,
		items: PropTypes.array,
		viewCollectionItems: PropTypes.array,
	}

	checkAnalytics = (brand) => {
		if (this.props.title) {
			const { signedIn, authorizedResources, selectedBDU, adobeUid } = this.context;
			const loginState = signedIn && authorizedResources.length > 0;
			return makingTrackingCall(
				`${this.props.orderNumber}|${this.props.title}|${brand.order - 1}|${brand.label}`,
				'BrandBar',
				loginState,
				selectedBDU,
				adobeUid
			);
		}
	}

	render() {
		const brandIcons = [];
		const { order, urlPrefix, items, viewCollectionItems, parentId, selectedBrand, selectedBrandCollectionId } = this.props;
		const title = this.props.title || 'Browse by Channel'
		// Only get the brands from VMS if a parentId is provided, otherwise read from local
		const brandsArray = (parentId) ?
			// VMS ordering starts at 0, and local brands ordering starts at 1.
			items.map(item => {
				return { ...item, order: item.order + 1 }
			})
			:
			brands;

		const sortedBrands = Object.values(brandsArray).sort((a, b) => {
			if (order === 'liveTvOrder') {
				return a.liveTvOrder - b.liveTvOrder;
			} else {
				return a.order - b.order;
			}
		});
		for (let brand of sortedBrands) {
			if (order === 'liveTvOrder' ? brand.liveTvOrder : brand.order) {
				let imageSrc, brandKey, brandPath, brandClass, brandOrigin;
				if (parentId) {  // brands from VMS
					imageSrc = brand.type === 'document' ? brand.resources.find(item => item.tag === 'brand_row')?.uri : brand.resources.find(item => item.type === 'thumbnail')?.uri;
					brandKey = brand.guid;
					brandPath = brand.data.origin_id || brand.data.deeplink_id;
					brandOrigin = brand.type === 'document' ? 'freeplay' : (brand.data.origin_id || brand.data.deeplink_id);
					brandClass = 'fromVms';
				} else {  // hardcoded brands
					imageSrc = brand.images.icon.backgroundImage;
					brandKey = brand.origin;
					brandPath = brand.origin;
					brandOrigin = brand.origin;
					brandClass = 'local';
				}

				if (viewCollectionItems) {
					let collectionPath = viewCollectionItems.find(item => item.data.deeplink_id === brandPath);
					brandPath = collectionPath ? collectionPath.guid : brandPath;
				}

				if (!this.props.brands || this.props.brands.includes(brandPath)) {
					let toUrl;
					if (brand.type === 'document') {   // get the relative path for the document type container
						toUrl = `${brand.resources.find(item => item.type === 'link')?.uri}`;
						let index0f_relative_path = toUrl.lastIndexOf('/view');
						toUrl = toUrl.substring(index0f_relative_path)

					} else {
						toUrl = (urlPrefix ? `${urlPrefix}${brandPath}` : '#')
					}
					const navLink = (
						<NavLink
							className="BrandBar-link"
							key={brandKey}
							to={toUrl}
							onClick={() => {
								localStorage.setItem('currentPage', toUrl.includes('shows') ? 'Shows' : 'Live TV')
								this.checkAnalytics(brand)
							}}
							isActive={() => (selectedBrandCollectionId ? selectedBrandCollectionId === brandPath : selectedBrand === brandOrigin)}
						>
							<img alt={brand.label} className={`BrandBar-link-image BrandBar-${brandOrigin} ${brandClass}`} src={imageSrc} />
						</NavLink>
					);
					brandIcons.push(navLink);
				}
			}
		}

		return (
			<>
				{order !== 'liveTvOrder' && <h3 className='BrandBar-title'>{title}</h3>}
				<div className="BrandBar">
					<TileSlider items={brandIcons} gridColumns={BrandBar.gridColumns} ref={this.brandSliderRef} brandRow={true} />
				</div>
			</>
		)
	}
}

const propMapping = ({ parentId, type }) => {
	return {
		parent: parentId,
		limit: 15,
		type: type
	}
}

export default fromVms(BrandBar, propMapping, true);
