import moment from 'moment'
export const formatDate = (timeStamp) => {
	timeStamp = new Date(timeStamp);
	return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(timeStamp);
}

// Formats a time like '12:37 PM'.
export const formatTime = (timeStamp, timeZone = null) => {
	timeStamp = new Date(timeStamp);
	let formatArgs = {
		hour: 'numeric',
		minute: '2-digit'
	};

	if (timeZone) {
		formatArgs.timeZone = timeZone;
	}

	return new Intl.DateTimeFormat('en-US', formatArgs).format(timeStamp);
}

// Formats a duration in seconds to a string like '1:29:55' or '2:48'.
export const formatDuration = (duration, formatType = 'series') => {
	duration = Number(duration);
	let hours = Math.floor(duration / 3600);
	let minutes = Math.floor(duration % 3600 / 60);
	let seconds = Math.floor(duration % 60);

	let timeString = '';
	if (hours > 0) {
		if (formatType === 'movie') {
			timeString = hours + 'h ' + (minutes < 10 ? '0' : '');
		} else {
			// Add hours and a trailing 0 for minutes, if applicable.
			timeString = hours + ':' + (minutes < 10 ? '0' : '');
		}
	}

	if (formatType === 'movie') {
		timeString += minutes + 'm';
	} else {
		timeString += minutes + ':' + (seconds < 10 ? '0' + seconds : seconds);
	}

	return timeString;
}

export const subtractDays = (days) => {
	let date = new Date();
	date.setDate(date.getDate() - days);
	let dateString = moment(date).format(); // '2016-06-08'
	return dateString;
}

export const formatGenre = (genre) => {
	let uppercaseFirstLetter = genre.charAt(0).toUpperCase();
	return uppercaseFirstLetter + genre.slice(1).replace(/_/g, ' ');
}

export const formatSeasonEpisode = (season, episode) => {
	if (season && episode) {
		return `S${season} E${episode}`;
	} else if (season) {
		return 'S' + season;
	} else if (episode) {
		return 'E' + episode;
	}

	return '';
}

export const truncateWithEllipsis = (text = '', length = 240) => {
	if (text.length < length) {
		return text;
	} else {
		const lastAllowedSpace = text.substr(0, length).lastIndexOf(' ');
		return text.substr(0, lastAllowedSpace) + '...';
	}
}

// Compares two arrays and returns whether their values are the same and in the same order.
export const shallowArrayEquals = (a, b) => {
	if (a.length !== b.length) {
		return false;
	}

	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) {
			return false;
		}
	}

	return true;
}

/**
 * Get remaining days for given days
 * @param {String} date
 * @param {Int} limitDays restricted to how many days
 */
export const getRemainingDays = (date, limitDays = false) => {
	if (date) {
		const windowDate = new Date(date),
			currentDate = new Date(),
			days = 1000 * 60 * 60;

		let hoursLeft = 0;
		hoursLeft = Math.floor((windowDate - currentDate) / days);

		let showDays = true;

		if (limitDays) {
			showDays = (limitDays * 24) > hoursLeft;
		}

		if (hoursLeft > 0 && showDays) {
			if (hoursLeft < 24) {
				if (hoursLeft <= 1) {
					return '1 hour';
				}
				return `${hoursLeft} hours`;
			} else if (hoursLeft < 48) {
				return '1 day';
			} else {
				return `${Math.floor(hoursLeft / 24)} days`;
			}
		}
	}
}


export const getElapsedDays = (date, limitDays = false) => {
	if (date) {
		const currentDate = new Date(date),
			windowDate = new Date(),
			days = 1000 * 60 * 60;

		let hoursLeft = 0;
		hoursLeft = Math.floor((windowDate - currentDate) / days);

		let showDays = true;

		if (limitDays) {
			showDays = (limitDays * 24) > hoursLeft;
		}

		if (hoursLeft > 0 && showDays) {
			if (hoursLeft < 24) {
				if (hoursLeft <= 1) {
					return (1 / 24);
				}
				return (hoursLeft / 24);
			} else if (hoursLeft < 48) {
				return 1;
			} else {
				return Math.floor(hoursLeft / 24);
			}
		}
	}
}

export const slugify = (string) => {
	const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
	const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
	const p = new RegExp(a.split('').join('|'), 'g')

	return (
		string.toString().toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			// eslint-disable-next-line no-useless-escape
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			// eslint-disable-next-line no-useless-escape
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '') // Trim - from end of text
	)
}

// Replaces tokens wrapped in the chosen delimiter using a key/value tokens object.
export const replaceTokens = (string, tokens, delimiter = '%') => {
	Object.entries(tokens).forEach(([key, value]) => {
		string = string.replace(`${delimiter}${key}${delimiter}`, value);
	});

	return string;
}

// Sorting the server response based on tehe order id
export const sortingResponsebyOrderID = (items) => {
	return items.sort((a, b) => {
		return a.order - b.order;
	})
}

export const optimizeImage = (urlString, width) => {
	let imageUrl = new URL(urlString);
	if (imageUrl.protocol.startsWith('http')) {
		imageUrl.searchParams.set('format', 'jpg');
		imageUrl.searchParams.set('width', width);
	}
	return imageUrl.toString();
}

// Extracts a string after `${key}${valueDelimiter}` and ending before `${endDelimiter}` (i.e. for cookies).
// Returns false if unsuccessful.
export const getValueForKey = (string, key, valueDelimiter = '=', endDelimiter = ';') => {
	let startIndex = string.indexOf(`${key}${valueDelimiter}`);
	if (startIndex === -1) {
		return false;
	}

	startIndex += key.length + 1;
	let endIndex = string.indexOf(endDelimiter, startIndex);
	if (endIndex === -1) {
		endIndex = string.length;
	}

	return string.substring(startIndex, endIndex);
}

export const getCookie = cName => {
	const cookieName = `${cName}=`;
	const allCookies = document.cookie.split(';');
	for (let i = 0; i < allCookies.length; i++) {
		let c = allCookies[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(cookieName) === 0) {
			return c.substring(cookieName.length, c.length);
		}
	}
	return '';
}

export const setCookie = (cName, cValue, expdays) => {
	let dotDomain = '';
	const domainParts = window.location.hostname.split('.');
	if (domainParts.length > 2) {
		dotDomain += '.' + domainParts.slice(-2).join('.');
	} else {
		dotDomain = window.location.hostname;
	}
	const date = new Date();
	date.setTime(date.getTime() + (expdays * 24 * 60 * 60 * 1000));
	const expires = `expires=${date.toUTCString()}`;
	document.cookie = `${cName}=${cValue};${expires};path=/;domain=${dotDomain}`;
}

export const isVideoRoute = route => {
	const pattern = /video/g;
	return pattern.test(route);
}

export const isPlayRoute = route => {
	const pattern = /action=play/g;
	return pattern.test(route);
}

export const isLiveTVRoute = route => {
	const pattern = /live-tv/g;
	return pattern.test(route);
}

export const isCustomMsgNeeded = bdu => {
	const bduList = ['bellaliant', 'manitoba'];
	return (bduList.indexOf(bdu.toLowerCase()) > -1);
}

export const validateAuthorization = (brandName, authorizedResources) => {
	// No need to check with Adobe for Global News as it's open to everyone
	if (brandName === 'globalnewsott') return true;

	return authorizedResources.find(resource => {
		return (resource.toLowerCase().search(brandName) !== -1)  // change to lower case for new channel case sensitivity support
	});
}

/**
 * Apple's mobile safari is now using Mac's User-Agent on devices powered by iPadOS to support/load desktop experiences by default.
 * This change is resulting in false positives when we try to identify device using DeviceDector.
 * `isIOS` helper function checks for existance of more than 1 touch inputs,
 * since Mac never had touch support, having 'MacIntel' in UA & touch input support confirms that it's iPadOS device.
 */
export const isIOS = (navigator, window) => {
	return (/iPad|iPhone|iPod/.test(navigator.platform) ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
		!window.MSStream;
}

export function getMobileOperatingSystem() {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	if (/android/i.test(userAgent)) {
		return 'Android';
	}

	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return 'iOS';
	}

	else if (navigator.maxTouchPoints &&
		navigator.maxTouchPoints > 2 &&
		/MacIntel/.test(navigator.platform)) {
		return 'iPadOS'
	}

	return '';
}

// Creates a unique guid for vms
export const guid = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
		s4() + '-' + s4() + s4() + s4();
};

/*Analytics for all the collection in the page need to check */

export const makingTrackingCall = (value, type, loginState, selectedBDU, adobeUid) => {
	let currentPage = localStorage.getItem('currentPage') ? localStorage.getItem('currentPage') : 'home';
	const consent = getCookie('consentCheck')
	let params = loginState ? {
		action: type,
		'auth.bdu': selectedBDU,
		'auth.status': 'authenticated',
		'auth.account': adobeUid,
	} : {
		action: type,
		'auth.status': 'non-authenticated',
	}

	if (type === 'HeroDotPick') {
		params['nav.rotator_pick'] = `${currentPage}|${value}`
	} else {
		params['nav.carouselpick'] = `${currentPage}|${value}`
	}
	if (window.CorusAdobeAnalytics.trackLink && consent !== 'declined') {
		window.CorusAdobeAnalytics.trackLink(true, params, 'Content Collection Tracking')
	}
}

export const configbyOrigin = (origin, data) => {
	const configData = JSON.parse(localStorage.getItem('config'));
	const brands = data ?? configData?.origins
	if (origin) {
		for (let key in brands) {
			if (origin === brands[key].origin || origin === key) {
				return brands[key]
			}
		}
	}
	return brands;
}

export const configbyAdobeID = (ID) => {
	const configData = JSON.parse(localStorage.getItem('config'));
	const brands = configData?.configuration?.entries?.brands
	for (let key in brands) {
		if (ID === brands[key].adobeID) {
			return brands[key]
		}
	}
	return brands;
}


export function getShowMetadata(air_year, seasons, genre, subgenre, tv_rating, duration) {
	let metadata = '';
	if (air_year) {
		metadata += `${air_year} | `;
	}

	if (!!seasons > 0) {
		if (metadata.length > 0) {
			metadata += '';
		}

		metadata += seasons === 1 ? `${seasons} Season | ` : `${seasons} Seasons | `;
	}

	// Parse genre data
	if (genre) {
		metadata += formatGenre(genre);
	}

	if (subgenre) {
		metadata += (genre ? ', ' : '') + formatGenre(subgenre);
	}

	if (tv_rating) {
		if (metadata.length > 0 && !metadata.endsWith('| ')) {
			metadata += '  |  ';
		}

		metadata += tv_rating.toUpperCase();
	}

	if (duration) {
		if (metadata.length > 0 && !metadata.endsWith('| ')) {
			metadata += '  |  ';
		}

		metadata += duration;
	}

	return metadata;
}