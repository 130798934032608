import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './AdvertisementBanner.scss';
import { byOrigin as brands } from '../../../brands/brands';
import { makingTrackingCall } from '../../../utils/helpers';
import UserContext from '../../contexts/UserContext';


const AdvertisementBanner = (props) => {

	let { heroImageURL, altTag, link, tag, origin, item } = props;
	const { signedIn, authorizedResources, selectedBDU, adobeUid } = useContext(UserContext);
	const loginState = signedIn && authorizedResources.length > 0;
	const [heroImage, setHeroImage] = useState(false)
	let placeholderImage = brands(origin) ? brands(origin).placeholderImages.showHero : '';
	const imageSrc = (heroImageURL && heroImage) ? heroImageURL : placeholderImage;

	const handleShowImage = () => {
		setHeroImage(true)
	}

	const handleTracking = (item) => {
		makingTrackingCall(
			/* For show tile page_name | collection_position | collection_title | position_in_collection | showTitle */
			`${item.order}|unavailable|unavailable|${item.label}`,
			'Carousel Pick',
			loginState,
			selectedBDU,
			adobeUid
		);
	}

	if (tag?.includes('internal')) {
		let index0f_relative_path = link.lastIndexOf('.com');
		let relativeUrl = link.substring(index0f_relative_path + 4)
		return (
			<NavLink to={relativeUrl} >
				<img alt={altTag} className="Banner-image" src={imageSrc}
					onClick={() => handleTracking(item)}
					onLoad={() => handleShowImage()}
					onError={(e) => {
						if (e.target.src !== placeholderImage) {
							e.target.onerror = null;
							e.target.src = placeholderImage;
						}
					}} />
			</NavLink>
		)
	}
	else if (tag?.includes('external')) {
		return (
			<a href={link} target="_blank" rel="noopener noreferrer">
				<img alt={altTag} className="Banner-image" src={imageSrc}
					onLoad={() => handleShowImage()}
					onClick={() => handleTracking(item)}
					onError={(e) => {
						if (e.target.src !== placeholderImage) {
							e.target.onerror = null;
							e.target.src = placeholderImage;
						}
					}} />
			</a>
		)
	}
	else {        						// not render any ad lane for improperly setup advertisement
		return null
	}

}

AdvertisementBanner.propTypes = {
	altTag: PropTypes.string,
	heroImageURL: PropTypes.string,
	link: PropTypes.string,
	tag: PropTypes.string,
	origin: PropTypes.string,
	item: PropTypes.any,
	checkPage: PropTypes.string,
};

export default AdvertisementBanner;