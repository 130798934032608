import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
import fromVms from '../../utility/fromVms';
import brands from '../../../brands/brands';
import './GenreBar.scss';
import { makingTrackingCall, truncateWithEllipsis } from '../../../utils/helpers';
import { addingPermutiveValues } from '../../../utils/permutiveHelper';

import UserContext from '../../contexts/UserContext';
import TileSlider from '../../widgets/slider/TileSlider/TileSlider';

const GenreBar = (props) => {
	const { signedIn, authorizedResources, selectedBDU, adobeUid } = React.useContext(UserContext);
	const brandSliderRef = React.useRef();

	const gridColumns = {
		xs: 3,
		sm: 4,
		mm: 5,
		md: 6,
		lg: 6,
		xm: 7,
		xl: 7
	};
	const checkAnalytics = (brand) => {
		const loginState = signedIn && authorizedResources.length > 0;
		const currentPage = props.section
		const sections = [currentPage, brand.label];
		addingPermutiveValues({
			content: {
				...(sections.every((item) => typeof item === 'string') ? { sections } : ''),
				type: currentPage,
				name: brand.data?.description || brand.data?.show_title,
				keywords: brand.data.keywords?.split('/')
			},
			user: {
				auth: {
					status: loginState ? 'authenticated' : 'non-authenticated',
					...(loginState && { bdu: selectedBDU })
				}
			}
		})
		return makingTrackingCall(
			`${props.orderNumber}|${props.title}|${brand.order - 1}|${brand.label}`,
			'GenreBar',
			loginState,
			selectedBDU,
			adobeUid
		);
	}
	const brandIcons = [];
	const location = useLocation();
	const { order, urlPrefix, items, viewCollectionItems, parentId, selectedBrand, match: { params: { collectionId } } } = props;
	const title = props.title || 'Browse by Genre';
	// Only get the brands from VMS if a parentId is provided, otherwise read from local
	const brandsArray = (parentId) ?
		// VMS ordering starts at 0, and local brands ordering starts at 1.
		items.map(item => {
			return { ...item, order: item.order + 1 }
		})
		:
		brands;

	const sortedBrands = Object.values(brandsArray).sort((a, b) => {
		if (order === 'liveTvOrder') {
			return a.liveTvOrder - b.liveTvOrder;
		} else {
			return a.order - b.order;
		}
	});

	for (let brand of sortedBrands) {
		if (order === 'liveTvOrder' ? brand.liveTvOrder : brand.order) {
			let brandKey, brandPath, brandOrigin;
			if (parentId) {  // brands from VMS
				brandKey = brand.guid;
				brandPath = brand.label || brand.data.deeplink_id;
				brandOrigin = brand.label || brand.data.deeplink_id;
				// brandClass = 'fromVms';
			} else {  // hardcoded brands
				brandKey = brand.origin;
				brandPath = brand.label
				brandOrigin = brand.label;
			}

			if (viewCollectionItems) {
				let collectionPath = viewCollectionItems.find(item => item.label === brandPath);
				brandPath = collectionPath ? collectionPath.guid : brandPath;
			}

			if (!props.brands || props.brands.includes(brandPath)) {
				let toUrl = brand.label !== 'Classics Page' ? (urlPrefix ? `${urlPrefix}/collection/${brand.guid}` : '#') : `/segmentedview/${location.state}`
				const navLink = (
					<div><NavLink
						className="GenreBar-link"
						key={brandKey}
						to={toUrl}
						onClick={() => {
							checkAnalytics(brand)
						}}
						isActive={() => (collectionId === brand.guid || selectedBrand === brandOrigin)}
					>
						{truncateWithEllipsis(brand.label, 25)}
					</NavLink></div>
				);
				brandIcons.push(navLink);
			}
		}
	}
	return (
		<>
			<h3 className='GenreBar-title'>{title}</h3>
			<div className="GenreBar" >
				<TileSlider items={brandIcons} gridColumns={gridColumns} ref={brandSliderRef} brandRow={true} />
			</div>
		</>)
}


const propMapping = ({ parentId }) => {
	return {
		parent: parentId,
		limit: 15,
	}
}
GenreBar.propTypes = {
	brands: PropTypes.array,
	order: PropTypes.string,
	orderNumber: PropTypes.number,
	title: PropTypes.string,
	selectedBrand: PropTypes.string,
	selectedBrandCollectionId: PropTypes.string,
	urlPrefix: PropTypes.string,
	parentId: PropTypes.string,
	items: PropTypes.array,
	viewCollectionItems: PropTypes.array,
	match: PropTypes.any,
	section: PropTypes.string

}

export default withRouter(fromVms(GenreBar, propMapping, true));
