import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import AllBrands from './AllBrands';
import globalHeroImage from '../../../assets/placeholders/hero/product-family.png'
import FreeplayHeroImage from '../../../assets/placeholders/hero/freeplay-posters.png'
import freeplaylogo from '../../../assets/icons/brands/white/freeplay.svg';
import './PromoPage.scss';
import './AppPromoSection.scss';
import AppPromoBox from './AppPromoBox';
import AppPromoSection from './AppPromoSection';
import AvailableDevices from './AvailableDevices';
import UserContext from '../../contexts/UserContext';
import Faq from './Faq';
import { getMobileOperatingSystem } from '../../../utils/helpers';
import withSearch from '../../utility/withSearch';

const PromoPage = () => {

	const faqRef = useRef();
	const userContext = useContext(UserContext);
	const devicePlatform = getMobileOperatingSystem();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const signFlow = () => {
		if (devicePlatform)
			navigateTo()
		else userContext.update('displayAuth', true)
	}
	let history = useHistory();

	const navigateTo = (path = '') => {
		if (devicePlatform)
			window.location.replace(`${window.location.origin}${path}`)
		else
			history.push(path);

	}

	return (
		<>
			<div className="Promo-wrapper Product-section" style={{  'min-height': `${ devicePlatform ? 'auto' : '90vh'}` }}>
				<AppPromoBox
					title1={<p>TV’s best channels. One App.<br />
						Get the Global TV App FREE!</p>}
					descriptionText='Watch 12 amazing channels, hit shows, free TV, news, Live AND On&nbsp;Demand! It’s true - One app’s really got it all*! Download and start streaming now!​'
					conditionalText='* Available content may vary through participating service providers. '
					primaryButton='view supported devices'
					primaryLink='#FaqSection'
					secondaryButton=''
					promoHeroImage={globalHeroImage}
					imageLabel='Product Family hero image'
				/>
				<div className='Brand-section'>
					<AllBrands />
					<AvailableDevices />
				</div>
			</div>
			<div className='AppInfo-wrapper'>
				<div className='AppInfo-section'>
					<AppPromoSection
						heading='Hassle-FREE Streaming​'
						subHeading='Get on the app and watch all the following for free:​'
						buttonText='Download and watch now'
						callbackCTA={() => { faqRef.current.scrollIntoView() }}
						bodyDescription={[
							'24/7 breaking news.',
							'The latest episodes from Global for 7 days.',
							'TV favourites from Food Network Canada, HGTV Canada, and more on Freeplay.'
						]}
					/>
				</div>
				<div className='AppInfo-section'>
					<AppPromoSection
						heading='Unlock more with your Cable Subscription'
						subHeading='Sign-in and enjoy even more:'
						buttonText='Sign-In with your TV Provider'
						callbackCTA={signFlow}
						bodyDescription={[
							'Watch the best TV channels – Live!',
							'Catch up on the latest episodes or binge entire seasons of great shows.',
							'Connect on up to five devices at once.'
						]}
					/>
				</div>
			</div>
			<div className="Promo-wrapper freeplay-section">
				<AppPromoBox
					logo={freeplaylogo}
					title1='Introducing Freeplay​'
					descriptionText='Freeplay is the place to go for thousands of hours of great shows and movies. Re-watch your favourites or find new shows to obsess over! Enjoy episodes from Global, Food Network Canada, HGTV Canada, W Network, Slice, Showcase, The HISTORY&reg; Channel, Adult Swim and National Geographic! And…the best part? The name says it all. It’s free!​'
					primaryButton='Download Global TV App'
					primaryLink='#FaqSection'
					secondaryButton='Explore Freeplay'
					callbackCTA={() => navigateTo('/view/db081274-9eef-11ec-bccd-0242ac110005')}
					promoHeroImage={FreeplayHeroImage}
					imageLabel='Freeplay poster image'
				/>
			</div>
			<div className='Faq-wrapper' id='FaqSection' ref={faqRef}>
				<Faq />
			</div>

		</>
	)
}

export default withSearch(PromoPage);