import React, { useState, useRef, useEffect, useContext } from 'react';
import './LinkShare.scss'
import PropTypes from 'prop-types'
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import Share from '../../../../assets/icons/share';
import facebook from './/socialIcons/facebook.svg';
import twitter from './socialIcons/twitter.svg';
import link from './socialIcons/copylink.svg';
import UserContext from '../../../contexts/UserContext';
import { getCookie } from '../../../../utils/helpers';

const LinkShare = ({ title }) => {

	const [showModal, setShowModal] = useState(false);
	const [toolTip, settoolTip] = useState(false)
	const refWrapper = useRef(null);
	const refModal = useRef(null)
	let currentUrl = window.location.href;
	const { authorizedResources, selectedBDU, signedIn, adobeUid } = useContext(UserContext);
	const login = (signedIn && authorizedResources) ? 'authenticated' : 'non-authenticated';

	useEffect(() => {

		function handleClickOutside(event) {
			if (refModal.current && !refModal.current.contains(event.target) && !refWrapper.current.contains(event.target)) {
				refWrapper.current.style.border = 'none';
				setShowModal(false)
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refModal]);

	const handleClick = (event) => {
		event.preventDefault();
		const currentState = showModal
		if (!currentState) {
			refWrapper.current.style.border = '1px solid white';
		}
		else {
			refWrapper.current.style.border = 'none';
		}
		setShowModal(!currentState)
		!showModal && sendAnalytics()
	}

	const handleClipboardCopy = () => {
		settoolTip(true)
		setTimeout(() => {
			settoolTip(false)
		}, 2000)
		sendAnalytics('link')
	}

	const sendAnalytics = (social) => {
		const consent = getCookie('consentCheck')
		if (consent !== 'declined') {
			const params = {
				action: 'Link Sharing Clicked',
				'content.share': title,
				...(social && { 'content.share.type': social }),
				'auth.status ': login,
				...(login === 'authenticated' && {
					'auth.bdu': selectedBDU,
					'auth.account': adobeUid
				})
			}
			window.CorusAdobeAnalytics.trackLink(true, params, 'Link Sharing Clicked')
		}
	}

	return (
		<div className='share-container' >
			<span ref={refWrapper} className='share-wrapper' onClick={(event) => handleClick(event)}>
				<Share />
			</span>
			{showModal &&
				<ul ref={refModal} className='share-overlay'>
					<li className='linkList' onClick={() => sendAnalytics('facebook')}>
						<FacebookShareButton
							url={currentUrl}
							className='linkButton'
						><img src={facebook} className='linkIcon' />Facebook
						</FacebookShareButton>
					</li>
					<li className='linkList' onClick={() => sendAnalytics('twitter')}>
						<TwitterShareButton
							url={currentUrl}
							className='linkButton'
						><img src={twitter} className='linkIcon' />Twitter
						</TwitterShareButton>
					</li>
					<li className='linkList' onClick={() => { navigator.clipboard.writeText(currentUrl); handleClipboardCopy() }}>
						<div className='linkButton' >
							<span className='linkIcon'>
								<img src={link} />
							</span>
							{!toolTip ? <span className='linkText' >Copy Link</span>
								: <span className='linkText' >
									Link Copied!
								</span>}
						</div>
					</li>
				</ul>}
		</div >
	)
}
LinkShare.propTypes = {
	title: PropTypes.string

}
export default LinkShare;