import React, { useEffect, useState, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import playIcon from '../../../../assets/icons/tile-video-play.svg';
import { getElapsedDays } from '../../../../utils/helpers';
import { queryVms } from '../../../../utils/vmsQuery';
import UserContext from '../../../contexts/UserContext';
import { validateAuthorization, getRemainingDays } from '../../../../utils/helpers';
import PropTypes from 'prop-types';
import './smartPlayButton.scss'

const SmartPlayButton = (props) => {

	const { data: { series_type }, type, origin } = props
	const [latestEpisode, setLatestEpisode] = useState(null);
	const [progressBar, setProgressBar] = useState(null);
	const [resumeAt, setResumeAt] = useState(0);
	const [smartPlayButtonText, setSmartPlayButtonText] = useState('')
	const history = useHistory();
	const isDisplaySmartPlay = props.tags.find(tag => tag.value === 'hide_smartplay')
	const userHistory = window.CorusJwplayer.LocalStorageService.Instance.items.sort((a, b) => { return b.timestamp - a.timestamp });
	let suggestedEpisode, currentlyWatching, lastWatched, adobe_resource_id, isAuth, percentWatched = 0;
	const { authorizedResources, signedIn, update, isAuthInProgress, configData } = useContext(UserContext)
	for (let key in configData?.origins) {
		if (key === origin) {
			adobe_resource_id = configData.origins[key].adobe_resource_id
		}
	}
	isAuth = validateAuthorization(adobe_resource_id, authorizedResources)

	function compareShow(a, b) {
		if (+a.data.season_number < +b.data.season_number)
			return -1
		else if (+a.data.season_number > +b.data.season_number)
			return 1
		// can assume for the rest that season_number is same
		else if (+a.data.episode_number < +b.data.episode_number)
			return -1
		else if (+a.data.episode_number > +b.data.episode_number)
			return 1
	}

	const sortingAndAuthCheck = (episodes) => {
		if (series_type !== 'seasonless') {
			episodes = episodes.filter(show => (show.data.season_number !== '' && show.data.episode_number !== ''))
		}
		let episode_sort = episodes.sort((a, b) => compareShow(a, b))
		let authData = episode_sort.filter(value => {
			const windowEndDate = value.data.public_window_end_date;
			const remainingDays = getRemainingDays(windowEndDate);
			return ((!windowEndDate && !remainingDays) || remainingDays !== undefined || (isAuth && signedIn))
		})
		return authData
	}

	const pickEpisodeFromTheOngoingSeason = (episodes) => {
		episodes = episodes.filter(show => (show.data.season_number !== '' && show.data.episode_number !== ''))
		let latestSeason = +episodes[0].data.season_number, index = 0;
		for (let i = 0; i < episodes.length; i++) {
			if (+episodes[i].data.season_number > latestSeason) {
				latestSeason = +episodes[i].data.season_number
				index = i
			}
		}
		return episodes[index];
	}
	const getLatestEpisode = async () => {
		const { results } = await queryVms({
			parent: props.guid,
			limit: 1,
			ordering: '-reference_date',
			data_fields: 'season_number__episode_number__public_window_end_date__duration__secondary_parent_container_id',
			type: 'episode'
		});
		suggestedEpisode = results[0]
		return suggestedEpisode
	}

	const getAllEpisodes = async () => {
		const queryData = await queryVms({
			parent: props.guid,
			type: 'episode',
			limit: 500,
			data_fields: 'season_number__episode_number__public_window_end_date__duration__secondary_parent_container_id',
			resource_tags: 'none'
		});
		return queryData.results
	}

	const getTheNextEpisode = (lastWatched, episodes) => {
		for (let i = 0; i < episodes.length - 1; i++) {
			if (lastWatched.guid === episodes[i].guid) {
				return episodes[i + 1]
			}
		}
		return null
	}

	const pickRecentReferenceDate = (sortedEpisodes) => {
		let recentEpisode = sortedEpisodes[sortedEpisodes.length - 1]
		let recentDate = recentEpisode.reference_date;
		sortedEpisodes.forEach(episode => {
			if (episode.reference_date > recentDate) {
				recentDate = episode.reference_date
				recentEpisode = episode
			}
		})
		return { recentDate, recentEpisode }
	}

	useEffect(() => {
		if (!isAuth) {
			isAuth = validateAuthorization(adobe_resource_id, authorizedResources)
		}
		const smartPlayForSeries = async () => {
			const watchHistory = []
			const allEpisodes = await getAllEpisodes();
			const sortedEpisodes = sortingAndAuthCheck(allEpisodes)
			userHistory.forEach(show => {
				(sortedEpisodes.filter(item => {
					if (show.mediaId === item.guid) {
						watchHistory.push(item)
					}
				}))
			})
			lastWatched = watchHistory[0]                    // choose first episode since it is the most recently watched based on timestamp
			currentlyWatching = lastWatched && userHistory.find(item => item.mediaId === lastWatched.guid);
			percentWatched = currentlyWatching?.percentage;

			if (!allEpisodes.length) {                           // if no episodes available then render no button
				setSmartPlayButtonText(null)
			}
			else if (allEpisodes.length && !sortedEpisodes.length && !isAuth) {
				signedIn ? setSmartPlayButtonText('SUBSCRIBE TO WATCH') : setSmartPlayButtonText('SIGN IN TO WATCH')
				let { data: { secondary_parent_container_id } } = pickRecentReferenceDate(allEpisodes)?.recentEpisode
				props.onCollectionClick(secondary_parent_container_id, 'smartPlayCollection')
			}

			else if (series_type === 'seasonless') {
				suggestedEpisode = await getLatestEpisode()
				if (lastWatched && watchHistory.find(history => suggestedEpisode.guid === history.guid) && percentWatched < 95) {
					setSmartPlayButtonText('CONTINUE LATEST EPISODE')
					setResumeAt(suggestedEpisode?.data?.duration * (percentWatched / 100));
					setLatestEpisode(suggestedEpisode)
					setProgressBar(<div className="VideoTile-progress-container">
						<div className="VideoTile-progress-bar" style={{ width: `${percentWatched}%` }}></div>
					</div>)
				}
				else {
					setSmartPlayButtonText('PLAY LATEST EPISODE')
					setLatestEpisode(suggestedEpisode)
				}
			}
			else if (lastWatched && percentWatched < 95) {   // for continuing the last watched episode
				suggestedEpisode = lastWatched
				setSmartPlayButtonText(`CONTINUE S${suggestedEpisode.data.season_number} E${suggestedEpisode.data.episode_number}`)
				setResumeAt(suggestedEpisode?.data?.duration * (percentWatched / 100));
				setLatestEpisode(suggestedEpisode)
				setProgressBar(<div className="VideoTile-progress-container">
					<div className="VideoTile-progress-bar" style={{ width: `${percentWatched}%` }}></div>
				</div>)
				props.onCollectionClick(suggestedEpisode.data.secondary_parent_container_id, 'smartPlayCollection')
			}
			else if (lastWatched && percentWatched > 95) {    // suggest the next episode
				suggestedEpisode = getTheNextEpisode(lastWatched, sortedEpisodes)
				if (suggestedEpisode) {
					setSmartPlayButtonText(`PLAY S${suggestedEpisode.data.season_number} E${suggestedEpisode.data.episode_number}`)
					setLatestEpisode(suggestedEpisode)
				}
				else {
					suggestedEpisode = sortedEpisodes[0]  // suggest the first episode again to rewatch
					setSmartPlayButtonText(`PLAY S${suggestedEpisode.data.season_number} E${suggestedEpisode.data.episode_number}`)
					setLatestEpisode(suggestedEpisode)
				}
				props.onCollectionClick(suggestedEpisode.data.secondary_parent_container_id, 'smartPlayCollection')
			}
			else {
				const isWithinLast30days = getElapsedDays(pickRecentReferenceDate(sortedEpisodes).recentDate)
				suggestedEpisode = isWithinLast30days <= 30 ? pickEpisodeFromTheOngoingSeason(sortedEpisodes) : sortedEpisodes[0]
				setSmartPlayButtonText(`PLAY S${suggestedEpisode.data.season_number} E${suggestedEpisode.data.episode_number}`)
				props.onCollectionClick(suggestedEpisode.data.secondary_parent_container_id, 'smartPlayCollection')
				setLatestEpisode(suggestedEpisode)
			}
		}
		const smartPlayForMovies = async () => {

			currentlyWatching = userHistory.find(item => item.mediaId === props.guid);
			percentWatched = currentlyWatching?.percentage;

			const windowEndDate = props.data.public_window_end_date;
			const remainingDays = getRemainingDays(windowEndDate);
			const authCheck = (!windowEndDate && !remainingDays) || remainingDays !== undefined || (isAuth && signedIn)

			if (!authCheck) {
				signedIn ? setSmartPlayButtonText('SUBSCRIBE TO WATCH') : setSmartPlayButtonText('SIGN IN TO WATCH')
			}
			else if (currentlyWatching && percentWatched < 95) {
				setSmartPlayButtonText('CONTINUE MOVIE')
				setLatestEpisode(props)
				setResumeAt(currentlyWatching.isCompleted ? 0 : props?.data?.duration * (percentWatched / 100));
				setProgressBar(<div className="VideoTile-progress-container">
					<div className="VideoTile-progress-bar" style={{ width: `${percentWatched}%` }}></div>
				</div>)
			}
			else {
				setLatestEpisode(props)
				setSmartPlayButtonText('PLAY MOVIE')
			}
		}
		type === 'series' ? smartPlayForSeries() : smartPlayForMovies()
	}, [isAuthInProgress, signedIn, authorizedResources])

	const smartPlay = () => {
		if (smartPlayButtonText === 'SUBSCRIBE TO WATCH' || smartPlayButtonText === 'SIGN IN TO WATCH') {
			update('displayAuth', true)
		}
		else {
			type === 'series' ? history.push(`/series/${props.guid}/episode/${latestEpisode?.guid}/?action=smartPlay&&resumeTime=${resumeAt}`)
				: window.location.href = `/movie/${latestEpisode?.guid}/?action=smartPlay&&resumeTime=${resumeAt}`
		}
	}

	return (
		(!isDisplaySmartPlay && smartPlayButtonText) ? <>
			<div className={`smartPlay-container ${props.randomEpisodeButton && 'smartPlayButton'}`}>
				<button className='ShowSection-button button-primary' onClick={() => smartPlay()}>
					<img alt={'smart play'} src={playIcon}></img>
					{smartPlayButtonText}
				</button>
				{progressBar}
			</div>
		</> : null)
}

SmartPlayButton.propTypes = {
	origin: PropTypes.string,
	guid: PropTypes.string,
	type: PropTypes.string,
	data: PropTypes.object,
	movieButton: PropTypes.any,
	series_type: PropTypes.string,
	seasons: PropTypes.number,
	randomEpisodeButton: PropTypes.bool,
	onCollectionClick: PropTypes.func,
	tags: PropTypes.array

}

export default withRouter(SmartPlayButton)