import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../widgets/dropdowns/Dropdown/Dropdown';
import HelpModal from '../../partials/HelpModal/HelpModal';
import LiveTVVideoPlayer from '../../partials/LiveTVVideoPlayer/LiveTVVideoPlayer';
import OnNowOnNext from '../../widgets/schedule/OnNowOnNext/OnNowOnNext';
import geolocation, { geolocationAcquiredEvent } from '../../../utils/geolocation';
import fromVms from '../../utility/fromVms';
import { addingPermutiveValues } from '../../../utils/permutiveHelper';
import UserContext from '../../contexts/UserContext';



class LiveTVChannel extends Component {

	static propTypes = {
		parentId: PropTypes.string,
		loading: PropTypes.bool,
		errorState: PropTypes.bool,
		items: PropTypes.array,
		channel: PropTypes.object,
		channelId: PropTypes.string,
	}

	state = {
		closestStation: null,
		displayHelp: false,
		geolocationAcquired: false,
		stations: null,
	}

	static contextType = UserContext;

	stationChanged = false;
	/**
	 * Browser local storage for selected stations
	 */
	localStorageName = 'selectedStations';

	constructor(props) {
		super(props);
		this.playerRef = React.createRef();
	}

	componentDidMount() {
		if (!geolocation.location) {
			document.addEventListener(geolocationAcquiredEvent, () => {
				this.setState({
					geolocationAcquired: true
				});
			});
			geolocation.acquireGeolocation();
		} else {
			this.setState({
				geolocationAcquired: true
			});
		}
	}

	getSelectedStation = ( origin ) => {
		let selectedStations = window.localStorage.getItem(this.localStorageName) || '{}';
		selectedStations = Object.assign({}, JSON.parse( selectedStations ));
		return selectedStations[origin] || false;
	}

	setSelectedStation = ( origin, channelGuid ) => {
		let selectedStations = window.localStorage.getItem(this.localStorageName) || '{}';
		selectedStations = Object.assign({}, JSON.parse( selectedStations ));
		selectedStations[origin] = channelGuid;
		localStorage.setItem(this.localStorageName, JSON.stringify(selectedStations));
	}

	helpButtonClick = () => {
		if (this.playerRef.current) {
			this.playerRef.current.pause();
		}
		this.setState({
			displayHelp: true,
		});
	}

	closeHelpModal = () => {
		const modal = document.getElementsByClassName('HelpModal')[0];
		modal.classList.add('Closing-transition');

		setTimeout(() => {
			this.setState({
				displayHelp: false,
			});
		}, 200)
	}

	componentDidUpdate(prevProps) {
		if (this.props.parentId !== prevProps.parentId) {
			this.setState({
				closestStation: null,
				stations: null
			});
		} else if (
			!this.props.loading &&
			!this.props.errorState &&
			!this.state.closestStation &&
			this.state.geolocationAcquired
		) {
			this.calculateClosestStation();
		}
	}

	// Assumes geolocation.location is set.
	calculateClosestStation = () => {
		let minDistance = Number.MAX_SAFE_INTEGER;
		let closestStation = null;
		let currentPage = localStorage.getItem('currentPage');

		if (!currentPage) {
			localStorage.setItem('currentPage', 'Live TV');
			currentPage = localStorage.getItem('currentPage');
		}
		const { signedIn, authorizedResources, selectedBDU } = this.context;
		const loginState = signedIn && authorizedResources.length > 0;
		const stations = this.props.items;
		const sections = [currentPage,stations[0].origin];

		addingPermutiveValues ({
			content: {
				...(sections.every((item) => typeof item === 'string') ? { sections } : ''),
				type: currentPage
			},
			user: {
				auth: {
					status: loginState ? 'authenticated' : 'non-authenticated',
					...(loginState && { bdu: selectedBDU })
				}
			}
		} )


		for (let station of stations) {
			if ( station.guid === this.props.channelId ) {
				closestStation = station;
				break;
			}
			let distance = geolocation.distanceToLocation(station.data.latitude, station.data.longitude);
			if (distance < minDistance) {
				minDistance = distance;
				closestStation = station;
			}
		}

		// use selected/stored station if no channel Id is present in the URL
		if ( ! this.props.channelId && this.getSelectedStation( closestStation.origin ) ) {
			// Get the channel from stored localstorage
			let storedStation = stations.filter((station) => station.guid === this.getSelectedStation( closestStation.origin ) )
			if ( storedStation.length > 0 ) {
				closestStation = storedStation[0]
			}

		}

		this.setState({
			closestStation,
			stations
		});
	}

	onStationChange = (stationLabel) => {
		this.stationChanged = true;
		const newStation = this.state.stations.find(item => {
			return item.label == stationLabel;
		});

		geolocation.setGeolocationCookie(newStation.data.latitude, newStation.data.longitude);

		this.setState({
			closestStation: newStation,
		});

		window.history.pushState('',`${newStation.label}`,`/channel/${newStation.guid}`);

		this.setSelectedStation( newStation.origin, newStation.guid );
	}

	shouldComponentUpdate(nextProps, nextState) {
		if ((this.state.closestStation && this.state.closestStation == nextState.closestStation) &&
			(this.state.displayHelp === nextState.displayHelp) &&
			(this.props.parentId === nextProps.parentId) &&
			(this.props.loading === false && nextProps.loading === false) &&
			(this.stationChanged === false)) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		if (!this.state.closestStation) return null; // TODO: loading state?

		const channelOptions = this.state.stations.map( el => {
			return {
				value: el.label,
				label: el.label
			}
		});

		const selectedChannel = (this.stationChanged) ? this.state.closestStation : this.props.channel;
		this.stationChanged = false;

		const currentChannel = selectedChannel ? selectedChannel : this.state.closestStation;
		const hideScheduleOrigins = ['globalnewsott'];
		const isHideSchedule = hideScheduleOrigins.includes(currentChannel.origin);

		const videoId = currentChannel.guid;

		return (
			<>
				<OnNowOnNext isHideSchedule={isHideSchedule} channelId={this.state.closestStation.data.external_channel_id} stationTimezone={this.state.closestStation.data.timezone} />
				{ this.state.displayHelp ? <HelpModal closeHelpModal={this.closeHelpModal} /> : null }
				<div className="LiveTV-player-wrapper">
					{ this.state.closestStation ? (
						<LiveTVVideoPlayer
							videoID={videoId}
							playerRef={this.playerRef}
						>
							<div className="LiveTV-buttons-overlay">
								{this.state.stations.length > 1 && <Dropdown options={channelOptions} value={selectedChannel ? selectedChannel.label : this.state.closestStation.label} onChange={(event) => this.onStationChange(event.target.value)} />}
								<div className="Video-overlay-helpButton" onClick={this.helpButtonClick}>?</div>
							</div>
						</LiveTVVideoPlayer>
					) : null }
				</div>
			</>
		);
	}
}

const propMapping = (props) => {
	return {
		limit: 15,
		parent: props.parentId,
	}
}

export default fromVms(LiveTVChannel, propMapping);