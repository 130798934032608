import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Auth from '../../../utils/Auth';
import Spinner from '../Spinner/Spinner';
import UserContext from '../../contexts/UserContext';
import { configbyAdobeID as brands } from '../../../utils/helpers';
import { queryVms } from '../../../utils/vmsQuery';


import './SignInButton.scss';

class SignInButton extends Component {
	static propTypes = {
		buttonText: PropTypes.string,
		buttonClass: PropTypes.string,
		enableChannelList: PropTypes.bool,
	}
	constructor() {
		super();
		this.state = {
			displayBDUList: false,
			bduData: [],
			bdu_viewID: localStorage.getItem('BDU_VIEW_ID')
		}

	}
	static contextType = UserContext;
	componentDidMount() {
		this.getData();
	}
	async getData() {
		if (!this.state.bdu_viewID) {
			const bduView = await queryVms({
				type: 'view',
				limit: 10,
				parent: process.env.REACT_APP_VMS_DISTRIBUTION_ID
			});
			bduView.results.forEach(item => {
				//Take out the bdu ID from the view call
				if (item.data['view_id'] === 'bdu') {
					this.setState({ bdu_viewID: item.guid })
				}
			});
		}
		let data = await queryVms({
			limit: 99,
			parent: this.state.bdu_viewID,
		})
		this.setState({ bduData: data });
	}
	LocalStorageService = window.CorusJwplayer.LocalStorageService;

	toggleBduList = () => {
		this.setState({ displayBDUList: !this.state.displayBDUList })
	}

	render() {
		if (this.context.isAuthInProgress) {
			return <Spinner color="white" />
		}
		const { authorizedResources, authorizedResourcesChecked, signedIn, selectedBDU } = this.context;
		const { buttonClass, buttonText, enableChannelList } = this.props;
		const bdus = this.state?.bduData?.results;
		let bduLogo = [];
		let selectedMVPDS = bdus?.filter(bdu => bdu.data.mso_id === selectedBDU);
		if (selectedMVPDS) {
			selectedMVPDS[0]?.resources.filter(element => {
				if (element.type === 'thumbnail')
					bduLogo = element.uri
			});
		}
		return (
			<div className="SignIn-Button">
				{signedIn && selectedMVPDS ? (
					enableChannelList ?
						<div className="SignIn-popup-wrapper" onMouseEnter={() => this.toggleBduList()} onMouseLeave={() => this.toggleBduList()}>
							<button className={buttonClass}>Sign Out</button>
							{(this.state.displayBDUList && selectedBDU) && <div className="channel-list-wrapper">
								<div className="current-bdu-title">You are currently signed in with:</div>
								<img alt={`Signed in with ${selectedMVPDS[0].label || selectedBDU}`} className="current-bdu-logo" src={bduLogo} />
								{authorizedResources.length > 0 && <div className="channel-list-title">You have access to these channels:</div>}
								<ul className={'channel-list-container zero-channe ls--' + (authorizedResources.length <= 0)}>
									{!authorizedResourcesChecked ?
										<Spinner color="black" /> :
										authorizedResources.length > 0 ?
											authorizedResources.map(resource => {
												return <li className="channel-list-item" key={brands(resource)?.origin}>{brands(resource)?.label}</li>
											}) : (authorizedResources.length === 0 || !authorizedResources) &&
											<div className="no-channel-access">Sorry you don&apos;t have access to any of the channels. Please contact your TV provider.</div>
									}
								</ul>
								<button className="Signout-btn button-primary" onClick={() => Auth.signOut()}>Sign Out</button>
							</div>}
						</div> :
						<button className={buttonClass} onClick={() => Auth.signOut()}>Sign Out</button>
				) : (
					<button className={buttonClass} onClick={() => {
						// when login, delete old binge watch information to avoid conflict
						this.LocalStorageService.Instance.deleteDataById('items')
						this.context.update('displayAuth', true)
					}}>{buttonText}</button>
				)}
			</div>
		)
	}
}

export default SignInButton;
