import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.scss';

import * as Views from './components/views';


import AdobeAnalytics from './components/analytics/adobe/AdobeAnalytics';
import UserContext, { createDefaultState as createUserContextState } from './components/contexts/UserContext';
import Header from './components/partials/Header/Header';
import Footer from './components/partials/Footer/Footer';
import ErrorBoundary from './components/utility/ErrorBoundary/ErrorBoundary';
import ScrollToTop from './components/utility/ScrollToTop';
import AuthModal from './components/partials/AuthModal/AuthModal';
import VmsAuth from './components/vmsauth/VmsAuth';
import DeviceDetector from 'device-detector-js';
import Auth from './utils/Auth';
import ReactPixel from 'react-facebook-pixel';
import OverlayMessageModal from './components/partials/OverlayMessageModal/OverlayMessageModal';
import { getCookie, getMobileOperatingSystem } from './utils/helpers';
const UA = navigator.userAgent;
const deviceDetector = new DeviceDetector();
const deviceType = deviceDetector.parse(UA).device.type;
const devicePlatform = getMobileOperatingSystem();

class AppRouter extends Component {

	shouldComponentUpdate() {
		// For some reason, without this, updating UserContext remounts entire app
		return false;
	}

	render() {
		const HeaderFooterRoutes = () => (
			<>
				<Header />
				<div className='App-contentWell'>
					<Switch>
						<Route exact path='/' component={Views.Home} />
						<Route exact path='/series/:seriesId/collection/:collectionId/' component={Views.Show} />
						<Route exact path='/series/:seriesId/' component={Views.Show} />
						<Route exact path='/shows/:brand/:showId/:showName/' component={Views.Show} />
						<ScrollToTop path='/shows/:brand/' component={Views.Shows} />
						<ScrollToTop path='/shows/:guid/' component={Views.Shows} />
						<ScrollToTop path='/shows/' component={Views.Shows} />
						<ScrollToTop path='/segmentedview/:guid/collection/:collectionId' component={Views.Segmented} />
						<Route path='/live-tv/:brand/:stream' component={Views.LiveTV} />
						<Route path='/live-tv/:brand/' component={Views.LiveTV} />
						<Route path='/live-tv/' component={Views.LiveTV} />
						<Route path='/activate/' component={Views.Activate} />
						<Route path='/roku/activate' component={Views.Activate} />
						<Route path='/view/:viewGuid/collection/:collectionId' component={Views.ViewFinder} />
						<Route path='/view/:viewGuid' component={Views.ViewFinder} />
						<Route path='/globaltvapp' component={Views.PromoPage} />

						<Route component={Views.NotFound} />
					</Switch>
				</div>
				<Footer />
			</>
		)
		if (deviceType === 'desktop' && devicePlatform === '') {
			return (
				<Router>
					<AdobeAnalytics />
					<VmsAuth />
					<Switch>
						<Route path='/series/:seriesId/season/:seasonId/episode/:videoId/' component={Views.Video} />
						<Route path='/series/:seriesId/episode/:videoId/' component={Views.Video} />
						<Route path='/video/:videoId/:parentId/:ordering/:order' component={Views.Video} />
						<Route path='/video/:videoId/:parentId' component={Views.Video} />
						<Route path='/channel/:videoId' component={Views.Video} />
						<Route path='/video/:videoId' component={Views.Video} />
						<Route path='/movie/:movieId/media/:videoId' component={Views.Video} />
						<Route path='/movie/:videoId' component={Views.VideoViewFinder} />
						<Route component={HeaderFooterRoutes} />
					</Switch>
				</Router>
			)
		} else if (window.location.pathname === '/activate' || window.location.pathname === '/roku/activate' || window.location.pathname === '/globaltvapp') {
			// Allow activate page for mobile
			return (
				<Router>
					<AdobeAnalytics />
					<VmsAuth />
					<Header signInOnly={true} />
					<Route path='/activate' component={Views.Activate} />
					<Route path='/roku/activate' component={Views.Activate} />
					<Route path='/globaltvapp' component={Views.PromoPage} />
					{/* <Route path='/signup' component={Views.signUpForm} /> */}
					<Footer />
				</Router>
			)
		}

		// Show App Promo page for non-desktop users
		return (
			<Router>
				<Switch>
					<Route path='/' component={Views.AppPromo} />
				</Switch>
			</Router>
		)

	}
}

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = createUserContextState(this);
	}
	async componentDidMount() {
		Auth.contextUpdate = this.state.updateIfChanged;
		ReactPixel.init('273252816343370', {}, { debug: true, autoConfig: false });
		ReactPixel.pageView();
		const consent = getCookie('consentCheck')
		const apiUrl = 'https://public-api.wordpress.com/geo/';    // API to fetch the Geo location
		try {
			const response = await fetch(apiUrl);
			if (!response.ok) {
				throw new Error('Region can not be determined');
			}
			const jsonData = await response.json();
			this.setState({ region: jsonData.region })
			if (jsonData?.region !== 'Quebec') {
				window.unBlockScript(jsonData.region)
			} else if (consent !== 'declined') {
				window.unBlockScript(consent || true)
			}
			window.evidonFunction()
		} catch (error) {
			console.error('There was a problem with the region tracking:', error);
		}
	}

	AuthMessageModal() {
		const { isEastlinkDialogShown, selectedBDU, signedIn } = this.state;
		if (this.state.configData && !isEastlinkDialogShown && selectedBDU === 'Eastlink' && signedIn) {
			const { presentEastlinkDialog, eastlinkDialogMessage, eastlinkDialogLearnMoreLink, eastlinkDialogTitle } = this.state.configData?.configuration?.entries?.application

			return (presentEastlinkDialog && <OverlayMessageModal
				heading={eastlinkDialogTitle}
				subHeading={eastlinkDialogMessage}
				buttonTextLeft={'LEARN MORE'}
				buttonTextRight={'OK'}
				buttonLeftClick={() => {
					window.open(eastlinkDialogLearnMoreLink, '_blank')
				}}
				buttonRightClick={() => {
					this.state.update('isEastlinkDialogShown', true)
					sessionStorage.setItem('isEastlinkDialogShown', true)
				}}
			/>)
		}
		return null
	}

	render() {

		return (
			<ErrorBoundary errorComponent={Views.Error}>
				<UserContext.Provider value={this.state}>
					{this.state.displayAuth ? <AuthModal /> : null}
					{!sessionStorage.getItem('isEastlinkDialogShown') && this.AuthMessageModal()}
					<div className='App'>
						<AppRouter />
					</div>
				</UserContext.Provider>
			</ErrorBoundary>
		);
	}
}

export default App;
