import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SignInButton from '../../../partials/SignInButton/SignInButton';
import Spinner from '../../../partials/Spinner/Spinner';
import UserContext from '../../../contexts/UserContext';
import VmsAuth from '../../../vmsauth/VmsAuth';
import { configbyOrigin as brandsByOrigin, getCookie } from '../../../../utils/helpers';
import AuthInstance, { VideoAuthInstance } from '../../../../utils/Auth';
import { validateAuthorization, getRemainingDays } from '../../../../utils/helpers';

import './VideoPlayer.scss';
import { queryVms } from '../../../../utils/vmsQuery';
import CustomError from '../../../views/Error/CustomError';

class VideoPlayer extends Component {
	static propTypes = {
		action: PropTypes.string,
		children: PropTypes.any,
		data: PropTypes.object,
		fullscreenMode: PropTypes.bool,
		guid: PropTypes.string,
		liveTv: PropTypes.bool,
		isShuffle: PropTypes.bool,
		origin: PropTypes.string,
		platform: PropTypes.string,
		requireAuth: PropTypes.bool,
		reference_date: PropTypes.string,
		resources: PropTypes.array,
		resumeTime: PropTypes.any,
		type: PropTypes.string,
		videoId: PropTypes.string,
		behaviour: PropTypes.string,
		playlistItems: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.bool,
		]),
		parentId: PropTypes.string,
		ordering: PropTypes.string,
		order: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
	}

	static contextType = UserContext;

	static pagePlayerCounter = 0;

	static defaultProps = {
		fullscreenMode: false,
	}

	playerInstance = null;

	LocalStorageService = window.CorusJwplayer.LocalStorageService.Instance;

	// Helper variables to help prevent race conditions.
	initialized = false;

	constructor(props) {
		super(props);

		this.state = {
			playerDivId: `corusPlayer${++VideoPlayer.pagePlayerCounter}`,
		}
		this.props.data.fromShuffle = window.location.search.includes('shuffle=true') ? true : false;
	}

	componentDidMount() {
		if (!this.context.isVmsAuthInProgress) {
			this.initializeVideo();
		}
		this.brands = this.context.configData?.configuration?.entries?.brands;
	}

	componentDidUpdate(prevProps) {
		if (this.initialized && (this.props.videoId !== prevProps.videoId || this.props.origin !== prevProps.origin)) {
			// Got a new video - re-initialize.
			this.initialized = false;
			this.initializeVideo();
		} else if (!this.initialized && !this.context.isVmsAuthInProgress) {
			// Video was not initialized on component mount; try to initialize again. (i.e., after sign in)
			this.initializeVideo();
		}

		let element = document.getElementsByClassName('videoplayerAuthMessage-copy')[0];

		if (this.context.displayAuth && !this.props.liveTv) {
			element.style.background = 'transparent';
		} else {
			if (element) {
				element.style.background = 'rgba(0, 0, 0, 0.7)';
			}
		}
		if (!this.brands)
			this.brands = this.context.configData?.configuration?.entries?.brands;
	}

	componentWillUnmount() {
		if (this.playerInstance) {
			this.playerInstance.unloadVideo();
		}
	}

	async initializeVideo() {
		if ((this.props.liveTv && !validateAuthorization(this.props.origin, this.context.authorizedResources)) || this.context.displayAuth || this.context.adobeUid === null) {
			// Do nothing; display live TV slate.

		} else if (!this.initialized) {
			this.initialized = true;

			let config = await this.getVideoConfig();
			let player = new window.CorusJwplayer.Player(config, { permutiveService: new window.CorusJwplayer.PermutiveService() });

			// Player AdBlock Config
			this.getAdBlockConfig().bindTo(player);

			// Player Analytics config
			let consentFlag = getCookie('consentCheck')
			if (this.context.region !== 'Quebec' || consentFlag !== 'declined') {
				this.getComScoreConfig().bindTo(player);
				this.getAdobeHeartbeatConfig().bindTo(player);
			}

			player.initialize();

			// Save player instance
			this.playerInstance = player;
		}
		// Else, video already initialized - do nothing.
	}

	async getVideoConfig() {
		const origin = this.props.origin || 'global';
		const playlist = await this.getVideoPlaylist();

		let config = {
			// debug: true,
			container: this.state.playerDivId,
			origin,
			playlist,
			jwOptions: {
				autoplay: this.props.action,
				resumeAt: this.props.resumeTime,
				renderCaptionsNatively: (this.props.origin === 'global'),
				captions: {
					fontSize: 10
				}
			},
			key: process.env.REACT_APP_JW_LICENSE_KEY,
			messageContainer: `.corusPlayer-${this.state.playerDivId}`,
			saveProgress: true,
			listbar: false,
			redirectionUrlFormat: '/video/{mediaid}/',
			themeColor: '#a91616',
		}

		if (window.location.search.includes('shuffle') || this.props.isShuffle) {
			config.redirectionUrlFormat = '/video/{mediaid}/';
		}
		/**
		 * Custom redirection pattern for binge watch
		 */
		if (this.props.parentId && !this.props.parentId.includes('shuffle')) {
			config.redirectionUrlFormat = '/video/{mediaid}/{parentid}/{ordering}/{order}/?action=play';
		}

		if (this.props.fullscreenMode) {
			config.jwOptions = {
				aspectratio: -1,
				width: '100%',
				height: '100%'
			}
		}

		/**
		 * Enable autoplay from binge watch 2nd item
		 * expects playlistItems props when video starts from drawer/collection from homepage
		 */
		if (!this.props.playlistItems && this.props.ordering) {
			config.jwOptions = config.jwOptions || {};
			config.jwOptions.autostart = true;
		}

		if (this.props.action === 'play') {
			config.jwOptions.autostart = true;
		}

		config.contentRestriction = {
			requireAuth: this.props.requireAuth,
			auth: {
				siteAuth: VideoAuthInstance,
				handler: {
					title: 'You\'re almost there!',
					message: 'Sign in using your TV Service Provider credentials to watch this episode.',
					ctaLabel: 'Sign in to watch',
				},
				authorizationHandler: {
					title: 'You\'re almost there!',
					message: 'TV Service provider subscription needed to watch this episode',
					ctaLabel: 'More Info',
					href: 'https://www.globaltv.com/channel-finder/',
				},
			},
			drm: this.getDrmConfig(),
		};

		if (!this.props.liveTv || this.props.origin === 'globalnewsott') {
			// Don't show ads for non-Global News live TV.
			config.adConfig = this.getAdConfig(origin);
		}

		// check for the DAI stream
		if (playlist[0]?.daiSetting) {
			config.adConfig.dai = true
		}
		return config;
	}

	async getVideoPlaylist() {
		let playlist = [];
		let videoItem = window.CorusJwplayer.VmsContentService.formatContentItem(this.props);
		const isAuth = validateAuthorization(this.props.origin, this.context.authorizedResources);

		if (this.props.guid) {
			videoItem = await this.applyPlaybackData(videoItem);
			// Retrieve the next episode in the current show from the VMS.
			if (this.props.type === 'episode') {
				// Previously, this was getting the most recent episode instead of the next. I have changed the props info
				// so that it reflects the correct behavior
				let nextVideo = JSON.parse(localStorage.getItem('randomShuffleArray')) || null;
				let shuffleSequence = + localStorage.getItem('shuffleSequence') || 0;

				// if it's from shuffle, then get the next random episode; otherwise, get as normal
				if (this.props.isShuffle) {

					if (nextVideo && shuffleSequence < nextVideo.results.length - 1) {
						shuffleSequence = ++shuffleSequence;
						localStorage.setItem('shuffleSequence', shuffleSequence)
					} else {
						shuffleSequence = 0;
						let eps = await queryVms({
							parent: this.props.data.primary_parent_container_id,
							type: 'episode',
							limit: 1
						})
						nextVideo = await queryVms({
							parent: this.props.data.primary_parent_container_id,
							type: ['episode'],
							limit: eps.count
						})
						// function to shuffle the array
						const shuffle = (array) => {
							var m = array.length, t, randomIndex;

							// While there remain elements to shuffle…
							while (m) {

								// Pick a remaining element…
								randomIndex = Math.floor(Math.random() * m--);

								if (array[randomIndex].order == localStorage.getItem('random number')) {
									t = array[array.length - 1];
									array[array.length - 1] = array[randomIndex];
									array[randomIndex] = t
								} else {
									// And swap it with the current element.
									t = array[m];
									array[m] = array[randomIndex];
									array[randomIndex] = t;
								}
							}
							if (array[0]?.guid === videoItem.mediaid) {
								t = array[array.length - 1];
								array[array.length - 1] = array[0];
								array[0] = t
							}
							return array;
						}
						nextVideo.results = nextVideo.results?.filter(value => {
							const windowEndDate = value.data.public_window_end_date;
							const remainingDays = getRemainingDays(windowEndDate);
							return ((!windowEndDate && !remainingDays) || windowEndDate != undefined || (isAuth && this.context.signedIn))
						})
						shuffle(nextVideo.results)
						this.LocalStorageService.saveData('randomShuffleArray', JSON.stringify(nextVideo));
						localStorage.removeItem('random number');
						localStorage.setItem('shuffleSequence', 0);
					}
				} else {
					localStorage.removeItem('shuffleSequence');
					nextVideo = await queryVms({
						parent: this.props.data.primary_parent_container_id,
						type: 'episode',
						limit: 1,
						ordering: 'reference_date',
						reference_date__gt: new Date(this.props.reference_date).getTime()
					});
				}

				if ((nextVideo.results.length === 1 && !this.props.isShuffle) || (this.props.isShuffle && nextVideo.results.length > 1)) {
					let i = shuffleSequence || 0;
					nextVideo = window.CorusJwplayer.VmsContentService.formatContentItem(nextVideo.results[i]);
					// Note that the next video is not playable (since it redirects, and would require Realtime data).
					// In order to make JW show the Next Up bug, we need to mock as though it is.
					nextVideo.sources = [{
						file: nextVideo.thumbnail,
						type: 'dash'
					}];
					let mediaItem = JSON.parse(localStorage.getItem('[Local Storage Service]')).filter(item => item.mediaId !== nextVideo.mediaid);
					localStorage.setItem('[Local Storage Service]', JSON.stringify(mediaItem));
					playlist.push(nextVideo);
				}
			}
			if (this.props.type === 'media') {
				if (this.props.parentId) {
					videoItem.parentid = this.props.parentId;
					videoItem.ordering = this.props.ordering;
					videoItem.order = this.props.order;

					let collectionData = await queryVms({
						parent: this.props.parentId,
						type: 'media',
						limit: 30,
						ordering: this.props.ordering,
					});
					// check for the locked content index
					let index = collectionData.results?.findIndex(value => {
						const windowEndDate = value.data.public_window_end_date;
						const remainingDays = getRemainingDays(windowEndDate);
						return (!(!windowEndDate && !remainingDays) || this.context.signedIn)
					})

					if (collectionData.results.length) {
						const currentVideoIndex = collectionData.results.findIndex(item => item.guid === this.props.guid);
						let nextVideoIndex = (currentVideoIndex + 1);
						if (this.props.behaviour === 'behaviour_looping' && nextVideoIndex >= collectionData.results.length) {
							nextVideoIndex = 0
						}
						let nextVideoItem = collectionData.results[nextVideoIndex];
						// If next video item available, Add item to the playlist
						if (nextVideoItem && nextVideoIndex != index) {
							// Format the vms content for videoplayer
							let formattedNextVideoItem = window.CorusJwplayer.VmsContentService.formatContentItem(nextVideoItem);

							/**
							 * If we need to avoid reloading the page when JW Player play next item from the playlist,
							 * we need to provide actual playable file to the video player.
							 * Because JW Player not providing fetch hook before playing the content
							 *
							 * Need pass parentId and orderId to the URL to get the continuation when videoplayer reloads before playing next item
							 */
							formattedNextVideoItem.parentid = this.props.parentId;
							formattedNextVideoItem.ordering = this.props.ordering;
							formattedNextVideoItem.order = nextVideoItem.order;
							/**
							 * Faking the video player to show next video item before ending the current video.
							 * because JW Player won't show the next video if we don't pass file to the playlist
							 */
							formattedNextVideoItem.sources = [{
								file: formattedNextVideoItem.thumbnail,
								type: 'dash'
							}];
							playlist.push(formattedNextVideoItem);
						}
					}


				}
			}
		} else {
			// If there is no GUID set, the video couldn't be retrieved from the VMS.
			videoItem.error = 'videoNotFoundError';
		}

		playlist.unshift(videoItem);
		return playlist;
	}

	// Retrieve playback data from content services and apply it to the video item.
	async applyPlaybackData(videoItem) {
		window.CorusJwplayer.VmsRealtimeService.setup({
			environmentUrl: process.env.REACT_APP_VMS_RTS_DOMAIN,
			...(this.props.origin === 'globalnewsott' && { platform: 'web_fairplay' })
		});

		// User is logged in for sure if suid exists, so check this first to potentially save an adobe auth call
		const isAuthenticated = (VmsAuth.suid) ? true : await AuthInstance.isAuthenticatedUser();

		const playbackData = await window.CorusJwplayer.VmsRealtimeService.getPlaybackData(
			this.props.videoId,
			isAuthenticated
		);

		const formattedPlaybackData = window.CorusJwplayer.VmsRealtimeService.formatPlaybackData(playbackData);

		return {
			...videoItem,
			...formattedPlaybackData
		};
	}

	getAdConfig(origin) {
		let brandOrigin;
		if (origin) {
			brandOrigin = brandsByOrigin(origin, this.brands);
		} else {
			// default brand origin
			this.props.origin ? console.log(`${origin} is not a valid origin. origin default to global`) : console.log('No origin set. origin default to global');
			brandOrigin = brandsByOrigin('global', this.brands);
		}
		// This below condition to check if global news ott channel add some diff ad path then regular videos in news section
		let adAccount = this.props.type === 'channel' ? brandOrigin.liveAdAccount : brandOrigin.adAccount;
		let adDescUrl = this.props.type === 'channel' ? brandOrigin.description_url : '';

		return {
			adUnitPath: adAccount + '/video/' + this.props.videoId,
			cmsId: brandOrigin.cmsId,
			environment: process.env.REACT_APP_ENV || 'prod',
			playerFormat: 'html5',
			customParams: {
				permutive: this.LocalStorageService.getDataById('_pdfps')?.replace('[', '')?.replace(']', '')?.replace(/"/g, '') || ''
			},
			...({
				adTagParameters: {
					description_url: adDescUrl,
					iu: adAccount
				},
			}),
		}
	}

	getAdBlockConfig() {
		return new window.CorusJwplayer.AdBlockSubscriber({
			container: `.corusPlayer-${this.state.playerDivId}`,
			title: 'We noticed you have an ad blocker enabled.',
			message: '<p>Ads keep our content available online for all to enjoy so please turn off any ad blockers to keep watching.</p>',
			ctaLink: 'https://www.globaltv.com/faq/#faq-section-2-question-13'
		})
	}

	getDrmConfig() {
		return {
			vmsRealtimeUrl: process.env.REACT_APP_VMS_RTS_DOMAIN,
			error: {
				ios: {
					title: '',
					message: '',
					link: 'https://itunes.apple.com/ca/app/global-video/id404050595',
					image: 'https://assets.globaltv.com/wp-content/uploads/2019/04/iOS-sample.jpg',
					linkTitle: 'Download Global Go'
				},
				android: {
					title: '',
					message: '',
					link: 'https://play.google.com/store/apps/details?id=com.shawmedia.smglobal',
					image: 'https://assets.globaltv.com/wp-content/uploads/2019/04/Android-sample.jpg',
					linkTitle: 'Download Global Go'
				},
				generic: {
					title: 'Sorry, playback not available',
					message: 'DRM not supported',
					link: '',
					image: '',
				}
			}
		}
	}

	getComScoreConfig() {
		let brandOrigin;
		if (this.props.origin) {
			brandOrigin = brandsByOrigin(this.props.origin, this.brands);
		} else {
			// Default brand origin
			brandOrigin = brandsByOrigin('global', this.brands);
		}
		const { c3, VAM_enabled, VAM_station_code } = brandOrigin.comScore;

		return new window.CorusJwplayer.ComscoreSubscriber({
			c1: '2',
			c2: '3005670',
			c3,
			VAM_enabled,
			VAM_station_code,
		})
	}


	getAdobeHeartbeatConfig() {
		let brandOrigin;
		if (this.props.origin && brandsByOrigin(this.props.origin, this.brands)) {
			brandOrigin = brandsByOrigin(this.props.origin, this.brands);
		} else {
			brandOrigin = brandsByOrigin('global', this.brands);
		}

		return new window.CorusJwplayer.AdobeVideoHeartbeatSubscriber({
			account: process.env.REACT_APP_ADOBE_REPORT_SUITE,
			channel: brandOrigin.origin,
			platform: 'globaltv',
			heartbeatServer: 'corus.hb.omtrdc.net',
			producer: '5F34123F5245B4A70A490D45@AdobeOrg',
			secureTrackingServer: 'smetrics.corus.ca',
			trackingServer: 'metrics.corus.ca',
		})
	}

	pause = () => {
		if (this.playerInstance) {
			this.playerInstance.pause();
			this.isPaused = true;
		}
	}

	render() {
		const { liveTv, origin, children } = this.props;
		const authorized = validateAuthorization(origin, this.context.authorizedResources);
		const windowEndDate = this.props.data.public_window_end_date;
		const remainingDays = getRemainingDays(windowEndDate);
		const liveTVOverlay = (
			<div className="VideoPlayer-authMessage">
				<div className="VideoPlayer-authMessage-content">
					<h1 className="VideoPlayer-authMessage-title">Live TV, Anytime</h1>
					{(!this.context.signedIn) ?
						<SignInButton buttonClass={'VideoPlayer-authMessage-cta button-primary'} buttonText={'Sign in to watch'} /> :
						!authorized ?
							<a
								className="VideoPlayer-authMessage-cta"
								href="https://www.globaltv.com/channel-finder/"
								target="_blank"
								rel="noopener noreferrer"
								draggable="false"
							>Subscribe To Watch</a> :
							null
					}
				</div>
			</div>
		)

		const shufflePlayOverlay = (
			this.context.isAuthInProgress ? <Spinner color="white" />
				: <CustomError message='Sorry, there are no videos to shuffle play for this show. Please try another show' link={`/series/${this.props.data.primary_parent_container_id}`} buttonText='OK' />
		)
		return (
			<div className={'VideoPlayer' + (this.props.fullscreenMode ? ' VideoPlayer--fullscreenMode' : '')}>
				{(liveTv && !authorized) ?
					liveTVOverlay : (!authorized && this.props.isShuffle && windowEndDate && remainingDays === undefined) ? shufflePlayOverlay :
						<div className={`VideoPlayer-playerContainer corusPlayer-${this.state.playerDivId} jwplayer-container`}>
							<div id={this.state.playerDivId} />
							<div className="VideoPlayer-inner--loading">
								<Spinner color="white" />
							</div>
							{children}
						</div>
				}
			</div>
		)
	}
}

export default VideoPlayer;
