import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../contexts/UserContext';
import fromVms from '../../utility/fromVms';
import HeroImage from './HeroImage/HeroImage';
import HeroContent from './HeroContent/HeroContent';
import TileSlider from '../../widgets/slider/TileSlider/TileSlider';
import { makingTrackingCall, truncateWithEllipsis } from '../../../utils/helpers';
import { configbyOrigin } from '../../../utils/helpers';

import './Hero.scss';

class Hero extends Component {
	static contextType = UserContext;

	state = {
		tileSelected: 0,
		heroItems: [],
		heroItemsLoaded: false,
	}

	static propTypes = {
		loading: PropTypes.bool,
		title:PropTypes.string,
		order:PropTypes.number,
		items: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.object,
		]),
	};

	componentWillUnmount() {
		this.context.update('withRotator', false);
	}

	componentDidUpdate() {
		// Create a validated array of hero items (ensure they have images).
		if (!this.props.loading && !this.state.heroItemsLoaded) {
			/**
			 * Make nav menu absolute over hero image
			 */
			this.context.update('withRotator', true);

			const heroItems = this.props.items.sort((a, b) => (a.order - b.order));
			const filteredHeroItems = [];

			heroItems.forEach(heroItem => {
				const rotatorImage = heroItem['resources'].find(image => image.tag === 'rotator_large');
				if (rotatorImage) {
					heroItem.image = rotatorImage.uri;
					filteredHeroItems.push(heroItem);
				}
			})

			this.setState({
				heroItems: filteredHeroItems,
				heroItemsLoaded: true,
			})
		}
	}

	updateDotSelected = (dotSelected, item) => {
		this.setState({
			tileSelected: dotSelected,
		});
		if (item) {
			const { signedIn, authorizedResources, selectedBDU, adobeUid } = this.context;
			const loginState = signedIn && authorizedResources.length > 0;
			return makingTrackingCall(
				`${this.props.order}|${this.props.title}|${dotSelected}|${this.state.heroItems[dotSelected].label}`,
				'HeroDotPick',
				loginState,
				selectedBDU,
				adobeUid
			);
		}
	}

	/**
	 * Get the rotator type from tags. default to 'default'
	 * @param {array} tags item tags
	 */
	getRotatorType = ( tags = [] ) => {
		var rotatorType = 'default';

		if ( tags ) {
			tags.forEach( ( tag ) => {
				if ( tag.type === 'action_decorator' && tag.value === 'action_open_external_link_resource' ) {
					rotatorType = 'link';
				}
			} )
		}
		return rotatorType;
	}

	render() {
		if (this.state.heroItems.length === 0) {
			return null;
		}
		const tilesToShow = { xs: 1 };
		var content = {};
		const heroImages = this.state.heroItems.map((heroItem, index) => {
			const { data: { display_subtitle, sub_label }, guid: showId, id, origin, type, tags } = heroItem;
			const subText = sub_label ? sub_label : display_subtitle;
			if ( this.state.tileSelected === index ) {
				const brandconfigData = configbyOrigin(origin);
				const { title: showName = '' } = heroItem.data;
				const contentListing = heroItem.data.content_listing;
				const showDesc = truncateWithEllipsis(subText, 160);
				const showBrandName = brandconfigData ? brandconfigData.label : '';
				const rotatorType = this.getRotatorType( tags );
				var ctaLink;
				if ( rotatorType === 'link' ) {
					ctaLink = heroItem.resources.find( resource => resource.type === 'link' );
				}

				var airingNetwork = origin;
				if ( heroItem.data.airing_network ) {
					airingNetwork = heroItem.data.airing_network;
				}
				content = {
					showName,
					showDesc,
					showBrandName,
					showId,
					origin,
					contentListing,
					airingNetwork,
					rotatorType,
					ctaLink,
					tags,
					type,
					order: this.props.order,
					title: this.props.title,
					dotSelected: this.state.tileSelected
				};
			}
			return <HeroImage altTag={subText} heroImageURL={heroItem.image} key={id} />;
		});

		return (
			<div className="Hero">
				<TileSlider
					items={heroImages}
					gridColumns={tilesToShow}
					enableDots={true}
					updateDotSelected={this.updateDotSelected}
				>
				</TileSlider>
				<HeroContent {...content} />
			</div>
		)
	}
}

const propMapping = (props) => {
	return {
		ordering: props.ordering || '-reference_date',
		origin: props.brands,
		primary_parent: props.showId,
		parent: props.parent,
		limit: props.count
	}
}

export default fromVms(Hero, propMapping);
