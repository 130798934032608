// @flow
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LiveFavourite from './lib/LiveFavourite';
import LiveUnfavourite from './lib/LiveUnfavourite';
import Locked from './lib/Locked';
import UserContext from '../../contexts/UserContext';
import { configbyAdobeID as brands } from '../../../utils/helpers';
import './ChannelLogo.scss'
import { byOrigin as brandsOrigin } from '../../../brands/brands';

/**
 * Component to render a channel in a TimeLine
 *
 * @param {ChannelProps} props
 * @param {string} props.name - Name of the channel
 */
const checkLikeState = (guid) => {
	let foundData = [];
	const likeObj = JSON.parse(localStorage.getItem('favorites')) || [];
	if (likeObj.length > 0) {
		foundData = likeObj.filter((like) =>
			like.group_id === guid
		)
	}
	return foundData.length > 0 ? foundData[0].guid : false;
}

const composeLogo = (channel) => {
	const { origin, label } = channel
	const brand = brandsOrigin(origin)
	const placeholderImage = brand ? brand.images.logo : '';
	const altTag = brand ? (origin === 'globalnewsott' ? `${brand.images?.altTag}${label}` : brand.images?.altTag) : label
	const logo = channel.resources.find((element) => (element.tag === 'logo_origin_4colour'))?.uri || placeholderImage
	return { logo, altTag }
}

const ChannelLogo = ({ origin, channel, guid, type, favCheck, label }) => {
	const { update, pageRefresh, authorizedResources, signedIn } = React.useContext(UserContext);
	const [isImageLoaded, setIsImageLoaded] = React.useState(false);
	const { logo, altTag } = composeLogo(channel)
	const likeState = checkLikeState(guid)
	const [like, setLike] = useState(!!likeState);
	const [likeHover, setLikeHover] = useState(false);
	const authChannel = authorizedResources.filter(auth => brands(auth).origin === origin)


	const handleFavorites = async (event) => {
		if (!(authChannel.length > 0 || origin === 'globalnewsott')) return null
		event.preventDefault();
		like ? await window.CorusJwplayer.VmsRealtimeService.deleteFavourite(localStorage.getItem('puid'), checkLikeState(guid)) : await window.CorusJwplayer.VmsRealtimeService.addFavourite(localStorage.getItem('puid'), guid, type);
		setLike(!like);
		await window.CorusJwplayer.VmsRealtimeService.userFavorites(localStorage.getItem('puid'), 0, type);
		favCheck(true)
		setTimeout(() => {
			update('pageRefresh', !pageRefresh);
		}, 500)
	}

	useEffect(() => {
		setLike(checkLikeState(guid))
		const image = new Image();
		image.onload = () => setIsImageLoaded(true);
		image.src = logo;

		return () => {
			image.onload = null;
		};
	}, [pageRefresh])
	return (
		<div className='channel__logo-container'>
			<div className={(authChannel.length > 0 || origin === 'globalnewsott') ? 'channel__logo' : 'channel__logo channel__logo-inactive'}>
				{<span className='channel__logo-icon' onClick={(event) => handleFavorites(event)} onMouseEnter={() => setLikeHover(!likeHover)} onMouseLeave={() => setLikeHover(!likeHover)} >
					{authChannel.length > 0 || origin === 'globalnewsott' ? (like || likeHover ? <LiveFavourite /> : <LiveUnfavourite />) : <Locked />}
				</span>}
				{(!isImageLoaded) ? <p style={{ color: '#f2f2f2' }}>{origin === 'global' ? label : origin}</p> :
					<img src={logo} alt={altTag} className={(authChannel.length > 0 || origin === 'globalnewsott') ? 'channel__img' : 'channel__img__inactive'} />
				}
			</div>
			{!(authChannel.length > 0 || origin === 'globalnewsott') && <div className='sign_in' onClick={() => update('displayAuth', true)}>{signedIn ? 'Subscribe to watch' : 'Sign in to watch'}</div>}
		</div>
	)
}
ChannelLogo.propTypes = {
	channel: PropTypes.object,
	guid: PropTypes.string,
	type: PropTypes.string,
	origin: PropTypes.string,
	favCheck: PropTypes.func,
	label: PropTypes.string,

};

export default ChannelLogo;